import * as ActionType from "./host.type";

const initialState = {
  host: [],
  pendingHost: [],
  country1: [],
  hostProfile: {},
  total: null,
  agencyDropdown: [],
  agencyWiseHost: [],
  totalCoin: null,
  history: []
};

export const hostReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.GET_HOST:
      return {
        ...state,
        host: action.payload.data,
        total: action.payload.total,
      };
    case ActionType.GET_PENDING_HOST:
      return {
        ...state,
        pendingHost: action.payload.data,
        total: action.payload.total,
      };
    case ActionType.CREATE_HOST:
      const data = [...state.host];
      data.unshift(action.payload);
      return {
        ...state,
        host: data,
      };

    case ActionType.UPDATE_HOST:
      return {
        ...state,
        host: state.host.map((data) =>
          data._id === action.payload.id ? action.payload.data : data
        ),
      };
    case ActionType.COUNTRY_HOST:
      return {
        ...state,
        country1: action.payload,
      };
    case ActionType.ACCEPT_HOST_REQUEST:
      return {
        ...state,
        host: state.host.filter(
          (hostAccept) => hostAccept._id !== action.payload && hostAccept
        ),
      };
    case ActionType.BLOCKED_HOST:
      return {
        ...state,
        host: state.host.map((hostBlock) => {
          if (hostBlock._id === action.payload.id) return action.payload.data;
          else return hostBlock;
        }),
        hostProfile: action.payload.data,
        agencyWiseHost: state.agencyWiseHost.map((hostBlock) => {
          if (hostBlock._id === action.payload.id) return action.payload.data;
          else return hostBlock;
        }),
      };

    case ActionType.APPROVED_HOST:
      return {
        ...state,
        host: state.host.map((hostApproved) => {
          if (hostApproved._id === action.payload.id)
            return action.payload.data;
          else return hostApproved;
        }),
        hostProfile: action.payload.data,
      };
    case ActionType.APPROVED_REQ_HOST:
      return {
        ...state,
        host: state.host.map((hostApproved) => {
          if (hostApproved._id === action.payload.id)
            return action.payload.data;
          else return hostApproved;
        }),
        hostProfile: action.payload.data,
      };
    case ActionType.GET_HOST_PROFILE:
      return {
        ...state,
        hostProfile: action.payload,
      };
    case ActionType.ADD_TOPIC_HOST:
      return {
        ...state,
        hostProfile: action.payload,
      };
    case ActionType.DELETE_TOPIC_HOST:
      return {
        ...state,
        hostProfile: action.payload,
      };
    case ActionType.UPDATE_HOST_COIN:
      return {
        ...state,
        host: action.payload.host,
      };
    case ActionType.GET_AGENCY_DROPdOWN:

      return {
        ...state,
        agencyDropdown: action.payload,
      };

    case ActionType.GET_HOST_AGENCY_WISE:

      return {
        ...state,
        agencyWiseHost: action.payload.data,
        total: action.payload.total,

      };

    case ActionType.GET_HOST_HISTORY:
      return {
        ...state,
        history: action.payload.history,
        total: action.payload.total,
        totalCoin: action.payload.totalCoin
      }
    case ActionType.GET_HOST_LIVE_HISTORY:
      return {
        ...state,
        history: action.payload.history,
        total: action.payload.total,
        totalCoin: action.payload.totalCoin
      }

    default:
      return state;
  }
};
