import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import {
  getVIPPlan,
  handleRenewalSwitch,
  deleteVIPPlan,
  isTopVipPlan,
} from "../../../store/vipPlan/action";

//routing
import { Link, useNavigate } from "react-router-dom";

//alert
import { alert, warning, permissionError } from "../../../util/Alert";
import Title from "../../../extra/Title";
import Button from "../../../extra/Button";
import Table from "../../../extra/Table";
import Pagination from "../../../extra/Pagination";
import ToggleSwitch from "../../../extra/ToggleSwitch";
import dayjs from "dayjs";
import {
  OPEN_DIALOGUE,
  CLOSE_DIALOGUE,
} from "../../../store/dialogue/dialogue.type";
import VipPlanDialogue from "./VipPlanDialogue";

const VipPlan = (props) => {
  const { vipPlan } = useSelector((state) => state.vipPlan);
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  useEffect(() => {
    dispatch(getVIPPlan());
  }, []);

  useEffect(() => {
    setData(vipPlan);
  }, [vipPlan]);

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setPage(0);
  };

  const handleClick = (userDetails) => {
    props.handleRenewalSwitch(
      userDetails,
      userDetails?.isActive === true ? false : true
    );
  };

  const handleIsTop = (userDetails) => {
    props.isTopVipPlan(userDetails);
  };
  // Delete Plan
  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteVIPPlan(id);
        }
      })
      .catch((err) => console.log(err));
  };

  const mapData = [
    {
      Header: "No",
      width: "20px",
      // Cell: ({ index }) => <span>{parseInt(index + 1)}</span>,
      Cell: ({ index }) => <span>{index + 1}</span>,
    },

    {
      Header: "Validity",
      body: "validity",
      Cell: ({ row }) => (
        <span>
          <span className="text-danger">{row?.validity} </span>{" "}
          <span>{row?.validityType} </span>
        </span>
      ),
    },
    // {
    //   Header: "Validity Type",
    //   body: "validityType",
    // },
    {
      Header: "Dollar",
      body: "dollar",
      sorting: { type: "client" },
    },
    {
      Header: "Rupee",
      body: "rupee",
      sorting: { type: "client" },
    },
    {
      Header: "Tag",
      body: "tag",
    },

    {
      Header: "isActive",
      body: "isActive",
      Cell: ({ row }) => (
        <ToggleSwitch value={row?.isActive} onClick={() => handleClick(row)} />
      ),
    },
    {
      Header: "isTop",
      body: "isTop",
      Cell: ({ row }) => (
        <ToggleSwitch value={row?.isTop} onClick={() => handleIsTop(row)} />
      ),
    },

    {
      Header: "Edit",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={`boxCenter userBtn text-primary fs-5`}
          btnColor={``}
          btnIcon={`far fa-edit`}
          // onClick={() => handleEdit(row)}

          onClick={() => {
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { data: row, type: "VipPlan" },
            });
          }}
          style={{
            borderRadius: "5px",
            margin: "auto",
            width: "40px",
            // backgroundColor: "#fff",
            // color: "#160d98",
          }}
        />
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          newClass={`text-danger boxCenter userBtn fs-5`}
          btnColor={``}
          btnIcon={`fa-regular fa-trash-can`}
          onClick={() => handleDelete(row?._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },

    // add more columns as needed
  ];
  return (
    <>
      <div className="mainUserTable">
        <div className="userTable">
          <div className="userHeader primeHeader">
            <div className="row">
              <Title name={`Vip Plan`} />
            </div>
          </div>
          <div className="primeHeader mt-4 row">
            {/* <i className="fas fa-plus fa-md"></i> */}
            <div className="col-md-6">
              <Button
                newClass={` border bgsuccess text-white bgsuccess text-white openDialogue`}
                btnIcon={`fas fa-plus fa-md`}
                btnName={`Add Plan`}
                btnColor={`btnthemePrime`}
                onClick={() => {
                  dispatch({
                    type: OPEN_DIALOGUE,
                    payload: { type: "VipPlan" },
                  });
                }}
                // navigate("/admin/addAgency");

                // onClick={handleChangeImage}
                style={{ borderRadius: "7px" }}
              />
              {dialogue && dialogueType === "VipPlan" && <VipPlanDialogue />}
            </div>
          </div>

          <div className="userMain">
            <div className="tableMain mt-3">
              <Table
                data={data}
                mapData={mapData}
                serverPerPage={rowsPerPage}
                serverPage={page}
                type={"client"}
              />
            </div>
            <div className="paginationFooter">
              <Pagination
                type={"client"}
                component="div"
                count={vipPlan?.length}
                serverPage={page}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={vipPlan?.length}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>

          <div className="userFooter primeFooter"></div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getVIPPlan,
  deleteVIPPlan,
  handleRenewalSwitch,
  isTopVipPlan,
})(VipPlan);
