import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Title from "../../extra/Title";
import Button from "../../extra/Button";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import ToggleSwitch from "../../extra/ToggleSwitch";
const moment = require("moment");
import {
  OPEN_DIALOGUE,
  CLOSE_DIALOGUE,
} from "../../store/dialogue/dialogue.type";
import {
  getAllStories,
  deleteStory,
} from "../../store/fakeStory/fakeStory.action";
import FakeStoryDialog from "./FakeStoryDialog";
import { warning } from "../../util/Alert";
const FakeStory = (props) => {
  const { fakeStory } = useSelector((state) => state.fakeStory);
  console.log("fakeStory", fakeStory);

  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  useEffect(() => {
    dispatch(getAllStories(currentPage, rowsPerPage));
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    setData(fakeStory);
  }, [fakeStory]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  // Delete Story
  const handleDelete = (id) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteStory(id);
        }
      })
      .catch((err) => console.log(err));
  };

  const mapData = [
    {
      Header: "No",
      width: "20px",
      // Cell: ({ index }) => <span>{parseInt(index + 1)}</span>,
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "Story",
      Cell: ({ row }) => (
        <div>
          {row.type === 2 && (
            <img
              src={row?.story}
              style={{ borderRadius: "25px" }}
              height={50}
              width={50}
              alt=""
            />
          )}
          {row?.type === 1 && (
            <video
              src={row?.story}
              style={{ borderRadius: "25px" }}
              height={50}
              width={50}
              controls
              muted
            />
          )}
        </div>
      ),
    },
    {
      Header: "Preview",
      Cell: ({ row }) => (
        <div>
          {row.typePrivew === 2 && (
            <img
              src={row?.preview}
              style={{ borderRadius: "25px" }}
              height={50}
              width={50}
              alt=""
            />
          )}
          {row?.typePrivew === 1 && (
            <video
              src={row?.preview}
              style={{ borderRadius: "25px" }}
              height={50}
              width={50}
              autoPlay
              controls
            />
          )}
        </div>
      ),
    },

    {
      Header: "Host",
      Cell: ({ row }) => <span>{row?.hostId?.name}</span>,
    },
    {
      Header: "Host Country",
      Cell: ({ row }) => <span>{row?.hostId?.countryId?.name}</span>,
    },
    {
      Header: "createdAt",
      Cell: ({ row }) => (
        <span>{moment(row?.createdAt).format("YYYY-MM-DD  hh:mm A")}</span>
      ),
    },
    {
      Header: "expiresAt",
      Cell: ({ row }) => (
        <span>
          {moment(row?.expiration_date).format("YYYY-MM-DD  hh:mm A")}
        </span>
      ),
    },
    {
      Header: "Delete",
      body: "delete",
      Cell: ({ row }) => (
        <Button
          newClass={`text-danger boxCenter userBtn fs-5`}
          btnColor={``}
          btnIcon={`fa-regular fa-trash-can`}
          onClick={() => handleDelete(row?._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "50px" }}
        />
      ),
    },
  ];
  return (
    <div>
      <div className="mainUserTable">
        <div className="userTable">
          <div className="userHeader primeHeader">
            <div className="row">
              <Title name={`Fake Story`} />
            </div>
          </div>
          <div className="primeHeader mt-4 row">
            {/* <i className="fas fa-plus fa-md"></i> */}
            <div className="col-md-6">
              <Button
                newClass={` border bgsuccess text-white bgsuccess text-white openDialogue`}
                btnIcon={`fas fa-plus fa-md`}
                btnName={`Add Story`}
                btnColor={`btnthemePrime`}
                onClick={() => {
                  dispatch({
                    type: OPEN_DIALOGUE,
                    payload: { type: "fakeStory" },
                  });
                }}
                // navigate("/admin/addAgency");

                // onClick={handleChangeImage}
                style={{ borderRadius: "7px" }}
              />
              {dialogue && dialogueType === "fakeStory" && <FakeStoryDialog />}
            </div>
          </div>

          <div className="userMain">
            <div className="tableMain mt-3">
              <Table
                data={data}
                mapData={mapData}
                serverPerPage={rowsPerPage}
                serverPage={currentPage}
                type={"server"}
              />
            </div>
            <div className="paginationFooter">
              <Pagination
                component="div"
                count={fakeStory?.length}
                type={"server"}
                serverPerPage={rowsPerPage}
                totalData={fakeStory?.length}
                serverPage={currentPage}
                onPageChange={handleChangePage}
                setCurrentPage={setCurrentPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>

          <div className="userFooter primeFooter"></div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { getAllStories, deleteStory })(FakeStory);
