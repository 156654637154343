import React, { useEffect, useState } from "react";
import Title from "../../../extra/Title";
import Button from "../../../extra/Button";
import { useLocation, useNavigate } from "react-router-dom";
import UserCallHistory from "./UserCallHistory";
import UserGiftHistory from "./UserGiftHistory";
import UserAdminHistory from "./UserAdminHistory";
import UserCoinPlanHistory from "./UserCoinPlanHistory";
import $ from "jquery";
import male from "../../../../assets/images/male.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  OPEN_DIALOGUE,
  CLOSE_DIALOGUE,
} from "../../../store/dialogue/dialogue.type";
import UserReferralHistory from "./UserReferralHistory";
const UserHistory = (props) => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  const [type, setType] = useState("Call");

  useEffect(() => {
    window.history.pushState(null, window.location.href);
    window.addEventListener("popstate", handelPreviousPage);
    return () => {
      window.removeEventListener("popstate", handelPreviousPage);
    };
  }, []);
  
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handelPreviousPage = () => {
    // localStorage.removeItem("dialogueData");
    if (state) {
      navigate(-1);
    } else {
      const userProfileData = JSON.parse(localStorage.getItem("userInfoGo"));
      if (userProfileData?.type == "userInfo") {
        dispatch({
          type: OPEN_DIALOGUE,
          payload: {
            type: userProfileData?.type,
            data: userProfileData?.dialogueData,
          },
        });

        let dialogueData_ = {
          dialogue: true,
          type,
          dialogueData: userProfileData?.dialogueData,
        };

        localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
      } else {
        localStorage.removeItem("dialogueData");
        dispatch({ type: CLOSE_DIALOGUE });
      }
    }
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", male);
    });
  });

  return (
    <>
      <div className={`mainPlanTable  hostHistory  mt-4`}>
        <div className="planTable">
          <div className="primeHeader">
            <div className="row ">
              <div className="col-6 title-head title-head ">
                <h4 className="page-title mb-0 ">
                  <span className="text-primary text-capitalize">
                  
                    {state ? state?.name : dialogueData?.name}
                  </span>
                  's User Details
                </h4>
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
                <Button
                  btnColor={`bg-primary`}
                  newClass={`themeFont boxCent text-white`}
                  onClick={handelPreviousPage}
                  style={{
                    borderRadius: "5px",
                    height: "36px",
                    width: "50px",
                  }}
                  btnIcon={`fa-solid fa-angles-left text-white fs-6`}
                />
              </div>
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan overflow-hidden">
              <div className="coin-plan-button">
                <div className="row p-3 d-flex align-items-center border-bottom">
                  <div className="col-sm-12 col-md-6 ">
                    <Title className="mt-0" name={type + " History"} />
                  </div>
                  <div className="col-sm-12 col-md-6 d-flex justify-content-end">
                    {/* <div className="me-3">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`btn-danger`}
                        btnIcon={`fa-solid fa-rotate-right pe-2`}
                        btnName={`Refresh`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "95px",
                        }}
                        onClick={handleRefresh}
                      />
                    </div> */}
                    <div className="">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white me-2`}
                        btnColor={`bg-primary`}
                        btnName={`Call`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "60px",
                        }}
                        onClick={() => setType("Call")}
                      />
                    </div>

                    {/* <div className="mx-3">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`btn-secondary`}
                        btnName={`Message`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "90px",
                        }}
                        onClick={() => setType("message")}
                      />
                    </div> */}
                    <div>
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`bg-warning`}
                        btnName={`Gift`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "60px",
                        }}
                        onClick={() => setType("Gift")}
                      />
                    </div>

                    <div className="mx-3">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`bg-danger`}
                        btnName={`Admin`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "70px",
                        }}
                        onClick={() => setType("Admin")}
                      />
                    </div>
                    <div className="">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`bg-info`}
                        btnName={`Coin Plan`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "90px",
                        }}
                        onClick={() => setType("coinPlan")}
                      />
                    </div>
                    <div className="mx-3">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`bg-success`}
                        btnName={`Referral`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "90px",
                        }}
                        onClick={() => setType("referral")}
                      />
                    </div>
                  </div>
                </div>
                {type === "Call" ? (
                  <UserCallHistory
                    props={state ? state?.id : dialogueData?._id}
                  />
                ) : type === "Gift" ? (
                  <UserGiftHistory
                    props={state ? state?.id : dialogueData?._id}
                  />
                ) : type === "Admin" ? (
                  <UserAdminHistory
                    props={state ? state?.id : dialogueData?._id}
                  />
                ) : type === "coinPlan" ? (
                  <UserCoinPlanHistory
                    props={state ? state?.id : dialogueData?._id}
                  />
                ) : (
                  type === "referral" && (
                    <UserReferralHistory
                      props={state ? state?.id : dialogueData?._id}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserHistory;
