import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";


import HostLiveHistory from "../History/HostLiveHistory";
import HostGiftHistory from "../History/HostGiftHistory";
import HostAdminHistory from "../History/HostAdminHistory";
import HostCallHistory from "../History/HostCallHistory";

import { useDispatch, useSelector } from "react-redux";
import {
  CLOSE_DIALOGUE,
  OPEN_DIALOGUE,
} from "../../../../store/dialogue/dialogue.type";
import Title from "../../../../extra/Title";
import Button from "../../../../extra/Button";

const HostHistory = (props) => {
  const { dialogueData, dialogue } = useSelector((state) => state.dialogue);

  const [type, setType] = useState("call");
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateAgencySettlementID=JSON.parse(localStorage.getItem("stateAgencySettlement"))

  useEffect(() => {
    window.history.pushState(null, window.location.href);
    window.addEventListener("popstate", handelPreviousPage);
    return () => {
      window.removeEventListener("popstate", handelPreviousPage);
    };
  }, []);

  const handelPreviousPage  = () => {
    // localStorage.removeItem("dialogueData");
    // if (state) {
    //   navigate(-1);
    //   dispatch({ type: CLOSE_DIALOGUE });
    // } else {
    //   const hostProfile = localStorage.getItem("hostProfile");
    //   const profile = JSON.parse(hostProfile);
    //   profile
    //     ? dispatch({
    //         type: OPEN_DIALOGUE,
    //         payload: {
    //           dialogue: true,
    //           type: "hostProfile",
    //           data: profile.dialogueData,
    //         },
    //       })
    //     : state
    //     ? navigate("/admin/agencySettlement")
    //     : dispatch({ type: CLOSE_DIALOGUE });
    // }
    if(state?.type == "agency" ? "" :state ){
      navigate(-1);
    }else{
      const getHostData=JSON.parse(localStorage.getItem("realHostInfo"))
      const agencyWiseHostData=JSON.parse(localStorage.getItem("agencyWiseHost"))
    
        if( getHostData?.type == "hostInfo"){
          dispatch({
            type: OPEN_DIALOGUE,
            payload: {
              type: getHostData?.type,
              data: getHostData?.dialogueData,
            },
          });
      
          let dialogueData_ = {
            dialogue: true,
            type,
            dialogueData:  getHostData?.dialogueData,
          };
      
          localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
        }else if(agencyWiseHostData?.type == "hostInfo"){
          dispatch({
            type: OPEN_DIALOGUE,
            payload: {
              type: agencyWiseHostData?.type,
              data: agencyWiseHostData?.dialogueData,
            },
          });
      
          let dialogueData_ = {
            dialogue: true,
            type,
            dialogueData:  agencyWiseHostData?.dialogueData,
          };
      
          localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
        }else{
          localStorage.removeItem("dialogueData");
          dispatch({ type: CLOSE_DIALOGUE });
        }
    }
  };
  let propValue;
  let propValueName;
  if (state || stateAgencySettlementID ) {
  
        propValue = state?.id  ? state?.id : stateAgencySettlementID?.id;
        propValueName=state?.name  ? state?.name  : stateAgencySettlementID?.name
    
} else {
    propValue = dialogueData?._id;
    propValueName=dialogueData?.name
}


  // const handleRefresh = () => {};
  return (
    <>
      <div className={`mainPlanTable  hostHistory ${state}`}>
        <div className="row ">
          <div className="col-12"></div>
        </div>
        <div className="planTable">
          <div className="primeHeader">
            <div className="row ">
            {/* <Title 
                  name={`Host History`}
                /> */}
              <div className="col-6 title-head title-head d-flex align-self-end">
                
               <h4 className="page-title mb-0 ">
                <span className="text-primary"> {propValueName} </span> 's Host Details
              </h4>
              </div>
              <div className="col-6 d-flex justify-content-end mt-3">
              <button
                    className="btn btn-primary btn-icon px-4"
                    onClick={handelPreviousPage }
                  >
                    <i class="fa-solid fa-angles-left text-white fs-6"></i>
                  </button>
              </div>
            </div>
          </div>
          <div className="planMain">
            <div className="coin-plan overflow-hidden">
              <div className="coin-plan-button mt-2">
                <div className="row p-3 d-flex align-items-center border-bottom">
                  <div className="col-sm-12 col-md-6 ">
                    <Title className="mt-0" name={type + " History"} />
                  </div>
                  <div className="col-sm-12 col-md-6 d-flex justify-content-end">
                    {/* <div>
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`bg-danger`}
                        btnIcon={`fa-solid fa-rotate-right pe-2`}
                        btnName={`Refresh`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "95px",
                        }}
                        // onClick={handleRefresh}
                      />
                    </div> */}
                    <div className="mx-3">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`bg-primary`}
                        btnName={`Call`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "60px",
                        }}
                        onClick={() => setType("call")}
                      />
                    </div>
                    <div>
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`bg-success`}
                        btnName={`Live`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "60px",
                        }}
                        onClick={() => {
                          setType("Live")
                        }}
                      />
                    </div>
                   
                    <div className="mx-3">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`bg-warning`}
                        btnName={`Gift`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "60px",
                        }}
                        onClick={() => setType("gift")}
                      />
                    </div>

                    <div className="mx-3">
                      <Button
                        newClass={`themeFont boxCenter userBtn text-white`}
                        btnColor={`bg-danger`}
                        btnName={`Admin`}
                        style={{
                          borderRadius: "5px",
                          margin: "auto",
                          width: "70px",
                        }}
                        onClick={() => setType("admin")}
                      />
                    </div>
                  </div>
                </div>

                {type === "Live" ? (
                  <HostLiveHistory
                    props={propValue}
                  />
                ) : 
                type === "gift" ? (
                  <HostGiftHistory
                    props={propValue}
                  />
                ) : type === "admin" ? (
                  <HostAdminHistory
                    props={propValue}
                  />
                ) : (
                  type === "call" && (
                    <HostCallHistory
                      props={propValue}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HostHistory;
