import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useNavigate } from "react-router-dom";
import {
  singleGetUser,
  updateUserCoin,
  blockSwitch,
} from "../../store/user/user.action";
import IOSSwitch from "@material-ui/core/Switch";
import { setToast } from "../../util/toast";

import EdiText from "react-editext";

import { Tooltip } from "@material-ui/core";
import { baseURL } from "../../util/config";
import $ from "jquery";
import {
  CLOSE_DIALOGUE,
  OPEN_DIALOGUE,
  OPEN_NOTIFICATION_DIALOGUE,
} from "../../store/dialogue/dialogue.type";
import FakeImage from "../../../assets/images/host3.jpeg";
import ToggleSwitch from "../../extra/ToggleSwitch";
import Title from "../../extra/Title";
import Input from "../../extra/Input";
import Notification from "../../Pages/Notification";

const UserInfo = (props) => {
  const [data, setData] = useState({});
  const [coin, setCoin] = useState();
  const [errorCoin, setErrorCoin] = useState("");
  const dispatch = useDispatch();
  const { dialogueData, dialogueNotification, dialogueNotificationType } =
    useSelector((state) => state.dialogue);

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    window.history.pushState(null, window.location.href);
    window.addEventListener("popstate", goPrevious);
    return () => {
      window.removeEventListener("popstate", goPrevious);
    };
  }, []);
  useEffect(() => {
    props.singleGetUser(dialogueData ? dialogueData?._id : state);
  }, [state]);

  const { userDetails } = useSelector((state) => state.user);
  useEffect(() => {
    setData(userDetails);
  }, [userDetails]);

  const handleSave = (val) => {
    if (val < 0 || val?.length > 9) {
      setToast("error", "Invalid Coin");
      setErrorCoin("InValid Coin");
    } else {
      const coinValid = isNumeric(val);
      if (!coinValid) {
        setToast("error", "Invalid Coin");
        setErrorCoin("InValid Coin");
      } else {
        props.updateUserCoin(val, data._id);
        setErrorCoin("");
      }
    }
  };
  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };

  const openNotification = (data, type) => {
    dispatch({
      type: OPEN_NOTIFICATION_DIALOGUE,
      payload: {
        data: data,
        type: type,
      },
    });
  };

  $("input[type=number]").on("mousewheel", function (e) {
    $(e.target).blur();
  });
  // open Image New Teb
  const handleOpenUrl = (url) => {
    window.open(url, "_blank");
  };

  const handleClick = (userDetails) => {
    props.blockSwitch(userDetails, userDetails.isBlock === true ? false : true);
  };

  const goPrevious = () => {
    dispatch({ type: CLOSE_DIALOGUE });
    localStorage.removeItem("dialogueData");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("userInfoGo");
  };
  const handleHistory = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const handleProfile = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };
    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
    localStorage.setItem("userInfo", JSON.stringify(dialogueData_));
  };

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", `${baseURL}` + "storage/male.png");
    });
  });

  return (
    <>
      <div className="mainUserTable">
        <div className="userTable">
          <div className="userHeader primeHeader">
            <div className="row">
              <div className="col-6">
                <Title name={`User Info`} />
              </div>
              <div className="col-6"></div>
            </div>
            <div className="mt-5 d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0  text-capitalize">
                <span className="text-primary"> {data?.name} </span> 's Details
              </h4>
              <div className="ProfileBtn d-flex ">
                <button
                  className="btn btn-danger btn-icon px-4 me-2"
                  onClick={() => openNotification(data, "NotificationUser")}
                >
                  <i class="fa-solid fa-bell text-white"></i>
                </button>
                <button
                  className="btn btn-success btn-icon px-4 me-2"
                  onClick={() =>
                    state
                      ? handleHistory(data, "hostHistory")
                      : handleProfile(dialogueData, "userHistory")
                  }
                >
                  <i class="fa-solid fa-history text-white"></i>
                </button>
                <button
                  className="btn btn-primary btn-icon px-4"
                  onClick={goPrevious}
                >
                  <i class="fa-solid fa-angles-left text-white fs-6"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="userInfoMain mt-3 px-3">
            <div>
              <div className=" settingMain">
                <div className="row">
                  <div className="col-lg-3 col-md-12 col-sm-12 p-0 child-column profileSec-2 mt-3">
                    <div className="dashDataBox  p-0 rounded-4">
                      <div
                        className="w-100 py-2 text-center text-capitalize h4  "
                        style={{ color: "#242859" }}
                      >
                        User
                      </div>

                      <div className="bg-white boxCenter py-4">
                        <div className="profileImage mx-auto mt-lg-0 mt-md-2 overflow-hidden rounded-circle  border gradient-custom">
                          <img
                            src={data?.image}
                            alt="Profile"
                            draggable="false"
                            onClick={() => handleOpenUrl(data?.image)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                      <div
                        className="bg-white"
                        style={{
                          borderRadius: "0 0 16px 16px",
                          paddingBottom: "43px",
                        }}
                      >
                        <div className=" ms-4 fsuh"> Name</div>
                        <div className="p-2 boxUserInfo mx-4 text-capitalize fsu">
                          &nbsp;&nbsp; {data.name}
                        </div>

                        <div className="mt-2">
                          <div className="pt-3  ms-4 fsuh"> Country</div>
                          <div className="p-2 boxUserInfo mx-4 text-capitalize fsu">
                            &nbsp;&nbsp; {data.country}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" child-column col-lg-9 mb-2 col-md-12 col-sm-12 text-secondary userData profileSec-2 p-0 mt-3">
                    <div className=" rounded-4 row ms-lg-3 ms-md-0 me-lg-1 me-md-0 dashDataBox m-0">
                      <div
                        className="py-2 text-center text-capitalize h4 fw-bold m-0"
                        style={{ color: "#242859" }}
                      >
                        User's Details
                      </div>
                      <div
                        className="bg-white py-3 mt-2"
                        style={{ borderRadius: "0 0 16px 16px" }}
                      >
                        <div
                          className="py-4"
                          style={{ borderRadius: "0 0 16px 16px" }}
                        >
                          <div className="row">
                            <div className="col-md-6 col-12 mb-2">
                              <div className=" ms-4 fsuh"> Age</div>
                              <div className="p-2 boxUserInfo ms-4 text-capitalize fsu">
                                &nbsp;&nbsp; {data.age}
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="d-flex ms-4 mt-3">
                                <div
                                  style={{ width: "110px" }}
                                  className="my-auto fsuh"
                                >
                                  Is Block :
                                </div>
                                <div>
                                  <ToggleSwitch
                                    value={data.isBlock}
                                    onClick={() => handleClick(data)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="pt-3  ms-4 mt-2 fsuh">
                                Gender
                              </div>
                              <div className="p-2 boxUserInfo ms-4 text-capitalize fsu">
                                &nbsp;&nbsp; {data.gender}
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div
                                className="d-flex mb-lg-4 mb-md-2 ms-4"
                                style={{ marginTop: "55px" }}
                              >
                                <div
                                  className="fsuh"
                                  style={{ width: "110px" }}
                                >
                                  Status :
                                </div>
                                <div>
                                  {data?.isOnline === true ? (
                                    <div
                                      className="bg-success py-1 px-2 d-inline-block text-white ms-2"
                                      style={{
                                        fontSize: "11px",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      Online
                                    </div>
                                  ) : (
                                    <div
                                      className="bg-danger py-1 px-2 d-inline-block text-white "
                                      style={{
                                        fontSize: "11px",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      Offline
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-12 mt-2">
                              <div className="pt-3  ms-4 mt-2 fsuh"> Email</div>
                              <div className="p-2 boxUserInfo ms-4 text-capitalize fsu">
                               &nbsp;&nbsp; {data.email ? data.email: "-"} 
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="pt-3 ms-4 mt-2 fsuh">
                                Login Type
                              </div>
                              <div className="p-2 boxUserInfo ms-4 text-capitalize fsu">
                                &nbsp;&nbsp;
                                {data.loginType === 0
                                  ? "Google"
                                  : data.loginType === 0
                                  ? "Facebook"
                                  : "Quick"}
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="pt-3  ms-4 mt-2 fsuh">Coin</div>
                              <div className="p-2 boxUserInfo ms-4 text-capitalize fsu">
                                <EdiText
                                  type="number"
                                  value={data?.coin}
                                  onSave={handleSave}
                                  style={{border : "none"}}
                                  className="text-dark"
                                />
                                {errorCoin && (
                                  <div style={{ color: "red" }}>
                                    {errorCoin}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="pt-3  ms-4 mt-2 fsuh">
                                LastLogin
                              </div>
                              <div className="p-2 boxUserInfo ms-4 text-capitalize fsu">
                                &nbsp;&nbsp; {data?.lastLoginDate}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {dialogueNotification &&
          dialogueNotificationType === "NotificationUser" && <Notification />}
      </div>
    </>
  );
};

export default connect(null, { singleGetUser, updateUserCoin, blockSwitch })(
  UserInfo
);
