import React, { useEffect, useState } from "react";
import Input from "../../../extra/Input";
import Button from "../../../extra/Button";
import MonetizationSetting from "./MonetizationSetting";
import { useSelector, connect } from "react-redux";
import { allSwitchType } from "../../../store/setting/setting.action";
import ToggleSwitch from "../../../extra/ToggleSwitch";
import Multiselect from "multiselect-react-dropdown";

const GoogleAdd = (props) => {
  const [type, setType] = useState("Google");
  const { appWiseSetting } = useSelector((state) => state.setting);
  const { appCreateObj } = props;
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const [appAdStatus, setAppAdStatus] = useState(false);
  const [appDialogBeforeAddShow, setAppDialogBeforeAddShow] = useState(false);
  const [appHowShowAds, setAppHowShowAds] = useState(false);
  const [appInsPreload, setAppInsPreload] = useState(false);
  const [appNbPreload, setAppNbPreload] = useState(false);
  const [allAdds, setAllAdds] = useState(false);

  const [appAddMainClickCount, setAppAddMainClickCount] = useState("");
  const [appAddInnerClickCount, setAppAddInnerClickCount] = useState("");
  const [appAddBackClickCount, setAppAddBackClickCount] = useState("");
  const [appAddNativeClickCount, setAppAddNativeClickCount] = useState("");
  const [appAddBannerClickCount, setAppAddBannerClickCount] = useState("");
  const [AppsflyerId, setAppsflyerId] = useState("");
  const [selectedValue, setSelectedValue] = useState([]);
  const [howShowAd, setHowShowAd] = useState([]);
  const [extraData, setExtraData] = useState();

  const [isGoogle, setIsGoogle] = useState(false);
  const [googleId1, setGoogleId1] = useState("");
  const [googleAPPSecreteId1, setGoogleAPPSecreteId1] = useState("");
  const [googleAPPId1, setGoogleAPPId1] = useState("");
  const [googleIns1, setGoogleIns1] = useState("");
  const [googleAPPOpen1, setGoogleAPPOpen1] = useState("");
  const [googleNative1, setGoogleNative1] = useState("");
  const [googleBanner1, setGoogleBanner1] = useState("");
  const [googleRewarded1, setGoogleRewarded1] = useState("");
  const [googleOtherId1, setGoogleOtherId1] = useState("");

  const [googleId2, setGoogleId2] = useState("");
  const [googleAPPSecreteId2, setGoogleAPPSecreteId2] = useState("");
  const [googleAPPId2, setGoogleAPPId2] = useState("");
  const [googleIns2, setGoogleIns2] = useState("");
  const [googleAPPOpen2, setGoogleAPPOpen2] = useState("");
  const [googleNative2, setGoogleNative2] = useState("");
  const [googleBanner2, setGoogleBanner2] = useState("");
  const [googleRewarded2, setGoogleRewarded2] = useState("");
  const [googleOtherId2, setGoogleOtherId2] = useState("");

  const [googleCreateObj, setGoogleCreateObj] = useState({});
  const [monetizationBtn, setMonetizationBtn] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    setGoogleCreateObj({
      googleId1,
      appAddMainClickCount,
      appAddInnerClickCount,
      appAddBackClickCount,
      appAddNativeClickCount,
      appAddBannerClickCount,
      AppsflyerId,
      extraData,
      googleAPPSecreteId1,
      googleAPPId1,
      googleIns1,
      googleAPPOpen1,
      googleNative1,
      googleBanner1,
      googleRewarded1,
      googleOtherId1,
      googleId2,
      googleAPPSecreteId2,
      googleAPPId2,
      googleIns2,
      googleAPPOpen2,
      googleNative2,
      googleBanner2,
      googleRewarded2,
      googleOtherId2,
    });
  }, [
    googleId1,
    appAddMainClickCount,
    appAddInnerClickCount,
    appAddBackClickCount,
    appAddNativeClickCount,
    appAddBannerClickCount,
    AppsflyerId,
    extraData,
    googleAPPSecreteId1,
    googleAPPId1,
    googleIns1,
    googleAPPOpen1,
    googleNative1,
    googleBanner1,
    googleRewarded1,
    googleOtherId1,
    googleId2,
    googleAPPSecreteId2,
    googleAPPId2,
    googleIns2,
    googleAPPOpen2,
    googleNative2,
    googleBanner2,
    googleRewarded2,
    googleOtherId2,
  ]);

  useEffect(() => {
    const dataCheck = Object.values(googleCreateObj).every(
      (value) => value !== ""
    );
    console.log("dataCheck :>> ", dataCheck);
    const dataAdd = dataCheck === true ? true : false;
    console.log("dataAdd :>> ", dataAdd);
    setMonetizationBtn(dataAdd);
  }, [googleCreateObj, dialogue]);

  useEffect(() => {
    if (dialogueData) {
      const data = appWiseSetting?.howShowAd?.map((data) => {
        return {
          name: data,
        };
      });
      setSelectedValue(data);
      setAppAdStatus(appWiseSetting?.appAdStatus);
      setAppHowShowAds(appWiseSetting?.appHowShowAds);
      setAppInsPreload(appWiseSetting?.appInsPreload);
      setAppNbPreload(appWiseSetting?.appNbPreload);
      setAllAdds(appWiseSetting?.allAdds);
      setAppDialogBeforeAddShow(appWiseSetting?.appDialogBeforeAddShow);

      setAppAddMainClickCount(appWiseSetting?.appAddMainClickCount);
      setAppAddInnerClickCount(appWiseSetting?.appAddInnerClickCount);
      setAppAddBackClickCount(appWiseSetting?.appAddBackClickCount);
      setAppAddNativeClickCount(appWiseSetting?.appAddNativeClickCount);
      setAppAddBannerClickCount(appWiseSetting?.appAddBannerClickCount);
      setAppsflyerId(appWiseSetting?.AppsflyerId);
      setExtraData(JSON.stringify(appWiseSetting?.extraData));
      setHowShowAd(appWiseSetting?.howShowAd);

      setIsGoogle(appWiseSetting?.google?.isGoogle);
      setGoogleId1(appWiseSetting?.google?.googleId1);
      setGoogleAPPSecreteId1(appWiseSetting?.google?.googleAPPSecreteId1);
      setGoogleAPPId1(appWiseSetting?.google?.googleAPPId1);
      setGoogleIns1(appWiseSetting?.google?.googleIns1);
      setGoogleAPPOpen1(appWiseSetting?.google?.googleAPPOpen1);
      setGoogleNative1(appWiseSetting?.google?.googleNative1);
      setGoogleBanner1(appWiseSetting?.google?.googleBanner1);
      setGoogleRewarded1(appWiseSetting?.google?.googleRewarded1);
      setGoogleOtherId1(appWiseSetting?.google?.googleOtherId1);
      setGoogleId2(appWiseSetting?.google?.googleId2);
      setGoogleAPPSecreteId2(appWiseSetting?.google?.googleAPPSecreteId2);
      setGoogleAPPId2(appWiseSetting?.google?.googleAPPId2);
      setGoogleIns2(appWiseSetting?.google?.googleIns2);
      setGoogleAPPOpen2(appWiseSetting?.google?.googleAPPOpen2);
      setGoogleNative2(appWiseSetting?.google?.googleNative2);
      setGoogleBanner2(appWiseSetting?.google?.googleBanner2);
      setGoogleRewarded2(appWiseSetting?.google?.googleRewarded2);
      if (selectedValue?.length < 0) {
        setSelectedValue(data);
      }
    }
  }, [appWiseSetting, dialogueData]);

  const handleClickAllSwitch = (type) => {
    props.allSwitchType(appWiseSetting?._id, type);
  };

  //onselect function of selecting multiple values
  function onSelect(selectedList, selectedItem) {
    setSelectedValue(selectedList);
    // howShowAd?.push(selectedItem?.name);
  }
  //onRemove function for remove multiple values
  function onRemove(selectedList, removedItem) {
    console.log("selectedList", selectedList);
    console.log("removedItem", removedItem);
    setSelectedValue(selectedList);
  }

  const handleNexToFb = () => {
    setMonetizationBtn(false);
    setType("FaceBook");
    const selectAdsData = selectedValue?.map((item) => item?.name);

    setGoogleCreateObj({
      ...appCreateObj,
      appDialogBeforeAddShow,
      appHowShowAds,
      appInsPreload,
      appNbPreload,
      allAdds,
      appAdStatus,
      isGoogle,
      googleId1,
      appAddMainClickCount,
      appAddInnerClickCount,
      appAddBackClickCount,
      appAddNativeClickCount,
      appAddBannerClickCount,
      AppsflyerId,
      extraData,
      howShowAd: selectAdsData,
      googleAPPSecreteId1,
      googleAPPId1,
      googleIns1,
      googleAPPOpen1,
      googleNative1,
      googleBanner1,
      googleRewarded1,
      googleOtherId1,
      googleId2,
      googleAPPSecreteId2,
      googleAPPId2,
      googleIns2,
      googleAPPOpen2,
      googleNative2,
      googleBanner2,
      googleRewarded2,
      googleOtherId2,
    });
  };

  const option = [{ name: "Facebook" }, { name: "Admob" }];

  return (
    <>
      <div>
        <div class="row px-4">
          <div class="col-xl-5 border-end">
            <div>
              <ul>
                <li
                  className="box MoniBoxShadow cursor"
                  style={{
                    background: `${type === "Google" ? "#E94235 " : "#c3c3c3"}`,
                    color: `${type === "Google" ? "#FFF" : "#616161"}`,
                    borderRadius: "5px",
                  }}
                  onClick={() => setType("Google")}
                >
                  <a href={() => false}>Google Adds</a>
                </li>

                <li
                  className={`box MoniBoxShadow cursor ${
                    monetizationBtn == true ? "disabled" : "MoniBoxShadow"
                  }`}
                  onClick={() =>
                    monetizationBtn == true ? " " : setType("FaceBook")
                  }
                  style={{
                    background: `${
                      type === "FaceBook" ? "#1399EF" : "#c3c3c3"
                    }`,
                    color: `${type === "FaceBook" ? "#FFF" : "#616161"}`,
                    opacity: `${monetizationBtn === true ? "0.2" : "1"}`,
                    borderRadius: "5px",
                  }}
                >
                  <a href={() => false}>FaceBook Adds </a>
                </li>
              </ul>

              {dialogueData && (
                <div
                  className="switchTitleColor"
                  style={{ marginTop: "4.5rem" }}
                >
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="d-flex justify-content-between my-4">
                        <div className="fw-bold fs-6 align-items-center d-flex">
                          Google & FaceBook Ads
                        </div>
                        <ToggleSwitch
                          onClick={() => {
                            handleClickAllSwitch("allAdds");
                          }}
                          value={allAdds}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="d-flex justify-content-between my-4">
                        <div className="fw-bold fs-6 align-items-center d-flex">
                          Dialog Before Adds Show
                        </div>
                        <ToggleSwitch
                          onClick={() => {
                            handleClickAllSwitch("appDialogBeforeAddShow");
                          }}
                          value={appDialogBeforeAddShow}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="d-flex justify-content-between my-4">
                        <div className="fw-bold fs-6 align-items-center d-flex">
                          Adds Status
                        </div>
                        <ToggleSwitch
                          onClick={() => {
                            handleClickAllSwitch("appAdStatus");
                          }}
                          value={appAdStatus}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="d-flex justify-content-between my-4">
                        <div className="fw-bold fs-6 align-items-center d-flex">
                          How Show Adds
                        </div>
                        <ToggleSwitch
                          onClick={() => {
                            handleClickAllSwitch("appHowShowAds");
                          }}
                          value={appHowShowAds}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="d-flex justify-content-between my-4">
                        <div className="fw-bold fs-6 align-items-center d-flex">
                          App Ins Preload
                        </div>
                        <ToggleSwitch
                          onClick={() => {
                            handleClickAllSwitch("appInsPreload");
                          }}
                          value={appInsPreload}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="d-flex justify-content-between my-4">
                        <div className="fw-bold fs-6 align-items-center d-flex">
                          App n b Preload
                        </div>
                        <ToggleSwitch
                          onClick={() => {
                            handleClickAllSwitch("appNbPreload");
                          }}
                          value={appNbPreload}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <Input
                  type={`number`}
                  label={`App Add Main Click Count `}
                  value={appAddMainClickCount}
                  min={0}
                  newClass={`col-6`}
                  placeholder={``}
                  onChange={(e) => {
                    setAppAddMainClickCount(e.target.value);
                  }}
                />
                <Input
                  type={`number`}
                  label={`App Add Inner Click Count `}
                  value={appAddInnerClickCount}
                  min={0}
                  newClass={`col-6`}
                  placeholder={``}
                  onChange={(e) => {
                    setAppAddInnerClickCount(e.target.value);
                  }}
                />
                <Input
                  type={`number`}
                  label={`App Add Back Click Count `}
                  value={appAddBackClickCount}
                  min={0}
                  newClass={`col-6`}
                  placeholder={``}
                  onChange={(e) => {
                    setAppAddBackClickCount(e.target.value);
                  }}
                />
                <Input
                  type={`number`}
                  label={`App Add Native Click Count `}
                  value={appAddNativeClickCount}
                  min={0}
                  newClass={`col-6`}
                  placeholder={``}
                  onChange={(e) => {
                    setAppAddNativeClickCount(e.target.value);
                  }}
                />
                <Input
                  type={`number`}
                  label={`App Add Native Click Count `}
                  value={appAddBannerClickCount}
                  min={0}
                  newClass={`col-6`}
                  placeholder={``}
                  onChange={(e) => {
                    setAppAddBannerClickCount(e.target.value);
                  }}
                />
                <Input
                  type={`text`}
                  label={`App Flyer Id`}
                  value={AppsflyerId}
                  newClass={`col-md-6 col-12`}
                  placeholder={`Enter You App Flyer Id....`}
                  onChange={(e) => {
                    setAppsflyerId(e.target.value);
                  }}
                />
                <div className="prime-input col-12">
                  <label htmlFor="extraData">Extra Data</label>
                  <textarea
                    name=""
                    className=""
                    id="extraData"
                    rows={10}
                    style={{
                      width: "100%",
                      height: "auto",
                      border: "1px solid #cbd5e1",
                      borderRadius: "6px 0 0 6px",
                      padding: "16px",
                      fontSize: "14px",
                      cursor: "pointer",
                      backgroundColor: "#dee2e694",
                    }}
                    value={extraData}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      try {
                        const newData = JSON.parse(newValue);
                        setExtraData(newValue);
                        setError("");
                      } catch (error) {
                        // Handle invalid JSON input
                        console.error("Invalid JSON input:", error);
                        setExtraData(newValue);
                        setError("Invalid JSON input");
                        document.getElementById("extraData").focus();
                      }
                    }}
                  ></textarea>
                  {error && (
                    <div className="ml-2 mt-1">
                      {error && <p className="text-error">{error}</p>}
                    </div>
                  )}
                </div>

                <div className="col-12">
                  <label for="googlePlayEmail" class="form-label">
                    Show Ads Platform
                  </label>
                  <Multiselect
                    options={option}
                    selectedValues={selectedValue}
                    onSelect={onSelect}
                    onRemove={onRemove}
                    displayValue="name"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-7"
            // style={{ maxHeight: "100vh", minHeight: "100vh", overflow: "auto" }}
          >
            {type == "FaceBook" ? (
              <MonetizationSetting googleCreateObj={googleCreateObj} />
            ) : (
              <>
                <div className="row">
                  <div className="d-flex justify-content-between">
                    <div className="mainTitle mt-2 mb-4">Google Adds </div>

                    {dialogueData && (
                      <div className="switchTitleColor ">
                        <span className="fw-bold fs-6 col-2">
                          Google Active
                        </span>
                        <ToggleSwitch
                          onClick={() => {
                            handleClickAllSwitch("isGoogle");
                          }}
                          value={isGoogle}
                        />
                      </div>
                    )}
                  </div>
                  <Input
                    type={`text`}
                    label={`ID 1 `}
                    value={googleId1}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleId1(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`ID 2 `}
                    value={googleId2}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleId2(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`App Secrete Id 1 `}
                    value={googleAPPSecreteId1}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleAPPSecreteId1(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`App Secrete Id 2 `}
                    value={googleAPPSecreteId2}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleAPPSecreteId2(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`App Id 1`}
                    value={googleAPPId1}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleAPPId1(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`App Id 2`}
                    value={googleAPPId2}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleAPPId2(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`INS 1`}
                    value={googleIns1}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleIns1(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`INS 2`}
                    value={googleIns2}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleIns2(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`App Open 1`}
                    value={googleAPPOpen1}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleAPPOpen1(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`App Open 2`}
                    value={googleAPPOpen2}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleAPPOpen2(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`Native 1`}
                    value={googleNative1}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleNative1(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`Native 2`}
                    value={googleNative2}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleNative2(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`Banner 1`}
                    value={googleBanner1}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleBanner1(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`Banner 2`}
                    value={googleBanner2}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleBanner2(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`Rewarded 1`}
                    value={googleRewarded1}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleRewarded1(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`Rewarded 2`}
                    value={googleRewarded2}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleRewarded2(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`Other Id 1`}
                    value={googleOtherId1}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleOtherId1(e.target.value);
                    }}
                  />
                  <Input
                    type={`text`}
                    label={`Other Id 2`}
                    value={googleOtherId2}
                    newClass={`col-lg-6 col-sm-12 `}
                    placeholder={``}
                    onChange={(e) => {
                      setGoogleOtherId2(e.target.value);
                    }}
                  />
                </div>
                <div className="d-flex align-items-center  justify-content-end">
                  <Button
                    btnName={`Next to fb setting...`}
                    btnIcon={`fa-solid fa-circle-arrow-right ms-2 `}
                    btnColor={`btnBlackPrime`}
                    style={{
                      width: "200px",
                      borderRadius: "6px",
                      display: "flex",
                      borderRadius: "6px",
                      flexDirection: "row-reverse",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={handleNexToFb}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { allSwitchType })(GoogleAdd);
