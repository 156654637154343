import React, { useEffect, useState } from "react";
import Button from "../../../extra/Button";
import { Navigate, useNavigate } from "react-router-dom";
import MultiAppSetting from "./MultiAppSetting";
import Input from "../../../extra/Input";
import GoogleAdd from "./GoogleAdd";
import {
  updateMultiAppSetting,
  addMultiAppSetting,
} from "../../../store/setting/setting.action";
import { connect, useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../../../store/dialogue/dialogue.type";
import ToggleSwitch from "../../../extra/ToggleSwitch";
import { allSwitchType } from "../../../store/setting/setting.action";
import { EMPTY_APP_WISE_SETTING } from "../../../store/setting/setting.type";

const MonetizationSetting = (props) => {
  const { appWiseSetting } = useSelector((state) => state.setting);
  const navigate = useNavigate();
  const { googleCreateObj } = props;

  console.log(
    "googleCreateObjgoogleCreateObjgoogleCreateObjgoogleCreateObj",
    googleCreateObj
  );

  const [isFaceBook, setIsFaceBook] = useState(false);
  const [faceBookId1, setFaceBookId1] = useState("");

  const [faceBookId2, setFaceBookId2] = useState("");
  const [faceBookAPPSecreteId1, setFaceBookAPPSecreteId1] = useState("");
  const [faceBookAPPSecreteId2, setFaceBookAPPSecreteId2] = useState("");
  const [platformAPPId1, setPlatformAPPId1] = useState("");
  const [platformAPPId2, setPlatformAPPId2] = useState("");
  const [platformIns1, setPlatformIns1] = useState("");
  const [platformIns2, setPlatformIns2] = useState("");
  const [platformAPPOpen1, setPlatformAPPOpen1] = useState("");
  const [platformAPPOpen2, setPlatformAPPOpen2] = useState("");
  const [platformNative1, setPlatformNative1] = useState("");
  const [platformNative2, setPlatformNative2] = useState("");
  const [platformBanner1, setPlatformBanner1] = useState("");
  const [platformBanner2, setPlatformBanner2] = useState("");
  const [platformRewarded1, setPlatformRewarded1] = useState("");
  const [platformRewarded2, setPlatformRewarded2] = useState("");
  const [platformOtherId1, setPlatformOtherId1] = useState("");
  const [platformOtherId2, setPlatformOtherId2] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (appWiseSetting) {
      setIsFaceBook(appWiseSetting?.facebook?.isFaceBook);
      setFaceBookId1(appWiseSetting?.facebook?.faceBookId1);

      setFaceBookId2(appWiseSetting?.facebook?.faceBookId2);
      setFaceBookAPPSecreteId1(appWiseSetting?.facebook?.faceBookAPPSecreteId1);
      setFaceBookAPPSecreteId2(appWiseSetting?.facebook?.faceBookAPPSecreteId2);
      setPlatformAPPId1(appWiseSetting?.facebook?.platformAPPId1);
      setPlatformAPPId2(appWiseSetting?.facebook?.platformAPPId2);
      setPlatformIns1(appWiseSetting?.facebook?.platformIns1);
      setPlatformIns2(appWiseSetting?.facebook?.platformIns2);
      setPlatformAPPOpen1(appWiseSetting?.facebook?.platformAPPOpen1);
      setPlatformAPPOpen2(appWiseSetting?.facebook?.platformAPPOpen2);
      setPlatformNative1(appWiseSetting?.facebook?.platformNative1);
      setPlatformNative2(appWiseSetting?.facebook?.platformNative2);
      setPlatformBanner1(appWiseSetting?.facebook?.platformBanner1);
      setPlatformBanner2(appWiseSetting?.facebook?.platformBanner2);
      setPlatformRewarded1(appWiseSetting?.facebook?.platformRewarded1);
      setPlatformRewarded2(appWiseSetting?.facebook?.platformRewarded2);
      setPlatformOtherId1(appWiseSetting?.facebook?.platformOtherId1);
      setPlatformOtherId2(appWiseSetting?.facebook?.platformOtherId2);
    }
  }, [appWiseSetting]);

  const handleSubmit = () => {
    const formData = new FormData();
    if (googleCreateObj?.logoType == 1) {
      formData.append("logo", googleCreateObj?.logoPath);
    } else {
      formData.append("logo", googleCreateObj?.logo);
    }
    if (googleCreateObj?.videoType == 1) {
      formData.append("adminVideo", googleCreateObj?.videoPath);
    } else {
      formData.append("adminVideo", googleCreateObj?.video);
    }
    formData.append("logoType", googleCreateObj?.logoType);
    formData.append("videoType", googleCreateObj?.videoType);
    formData.append("stripeSecretKey", googleCreateObj?.stripeSecretKey);
    formData.append(
      "stripePublishableKey",
      googleCreateObj?.stripePublishableKey
    );
    formData.append("razorPaySecretKy", googleCreateObj?.razorPaySecretKy);
    formData.append("agoraKey", googleCreateObj?.agoraKey);
    formData.append(
      "razorPayPublishableKey",
      googleCreateObj?.razorPayPublishableKey
    );
    formData.append("quickLogin", googleCreateObj?.quickLogin);
    formData.append("googleLogin", googleCreateObj?.googleLogin);
    formData.append("razorPaySwitch", googleCreateObj?.razorPaySwitch);
    formData.append("stripePay", googleCreateObj?.stripePay);
    formData.append("bhim", googleCreateObj?.bhim);
    formData.append("googlePay", googleCreateObj?.googlePay);
    formData.append("phonePe", googleCreateObj?.phonePe);
    formData.append("paytm", googleCreateObj?.paytm);
    formData.append("upiPaySwitch", googleCreateObj?.upiPaySwitch);
    formData.append("googleInAppSwitch", googleCreateObj?.googleInAppSwitch);
    formData.append("appName", googleCreateObj?.appName);
    formData.append("packageName", googleCreateObj?.packageName);
    formData.append("agoraCertificate", googleCreateObj?.agoraCertificate);
    formData.append("privacyPolicyLink", googleCreateObj?.privacyPolicyLink);
    formData.append("appVersion", googleCreateObj?.appVersion);
    formData.append("termAndCondition", googleCreateObj?.termAndCondition);
    formData.append("appRedirectUrl", googleCreateObj?.appRedirectUrl);
    formData.append("ipApiBaseUrl", googleCreateObj?.ipApiBaseUrl);
    formData.append("ipApiEndPoint", googleCreateObj?.ipApiEndPoint);
    formData.append("AppsflyerId", googleCreateObj?.AppsflyerId);
    formData.append("extraData", googleCreateObj?.extraData);
    formData.append("isAppActive", googleCreateObj?.isAppActive);
    formData.append("isFakeCall", googleCreateObj?.isFakeCall);
    formData.append("fakeCallDuration", googleCreateObj?.fakeCallDuration);
    formData.append("fakeCallCount", googleCreateObj?.fakeCallCount);
    formData.append("chargeForMatchMale", googleCreateObj?.chargeForMatchMale);
    formData.append(
      "chargeForMatchFemale",
      googleCreateObj?.chargeForMatchFemale
    );
    formData.append("loginBonus", googleCreateObj?.loginBonus);
    formData.append("maxLoginBonus", googleCreateObj?.maxLoginBonus);
    formData.append("durationOfFreeCall", googleCreateObj?.durationOfFreeCall);
    formData.append("referralBonus", googleCreateObj?.referralBonus);
    formData.append("verifyPaymentTime", googleCreateObj?.verifyPaymentTime);
    formData.append("freeStoryView", googleCreateObj?.freeStoryView);
    formData.append("minLiveView", googleCreateObj?.minLiveView);
    formData.append("maxLiveView", googleCreateObj?.maxLiveView);
    formData.append("googleInAppKey", googleCreateObj?.googleInAppKey);
    formData.append("googleInAppEmail", googleCreateObj?.googleInAppEmail);

    // add click count

    formData.append(
      "appAddMainClickCount",
      googleCreateObj?.appAddMainClickCount
    );
    formData.append(
      "appAddInnerClickCount",
      googleCreateObj?.appAddInnerClickCount
    );
    formData.append(
      "appAddBackClickCount",
      googleCreateObj?.appAddBackClickCount
    );
    formData.append(
      "appAddNativeClickCount",
      googleCreateObj?.appAddNativeClickCount
    );
    formData.append(
      "appAddBannerClickCount",
      googleCreateObj?.appAddBannerClickCount
    );
    formData.append("howShowAd", googleCreateObj?.howShowAd);
    // google

    formData.append("upiPayKey", googleCreateObj?.upiPayKey);
    formData.append("googleId1", googleCreateObj?.googleId1);
    formData.append(
      "googleAPPSecreteId1",
      googleCreateObj?.googleAPPSecreteId1
    );
    formData.append("googleAPPId1", googleCreateObj?.googleAPPId1);
    formData.append("googleIns1", googleCreateObj?.googleIns1);
    formData.append("googleAPPOpen1", googleCreateObj?.googleAPPOpen1);
    formData.append("googleNative1", googleCreateObj?.googleNative1);
    formData.append("googleBanner1", googleCreateObj?.googleBanner1);
    formData.append("googleRewarded1", googleCreateObj?.googleRewarded1);
    formData.append("googleOtherId1", googleCreateObj?.googleOtherId1);
    formData.append("googleId2", googleCreateObj?.googleId2);
    formData.append(
      "googleAPPSecreteId2",
      googleCreateObj?.googleAPPSecreteId2
    );
    formData.append("googleAPPId2", googleCreateObj?.googleAPPId2);
    formData.append("googleIns2", googleCreateObj?.googleIns2);
    formData.append("googleAPPOpen2", googleCreateObj?.googleAPPOpen2);
    formData.append("googleNative2", googleCreateObj?.googleNative2);
    formData.append("googleBanner2", googleCreateObj?.googleBanner2);
    formData.append("googleRewarded2", googleCreateObj?.googleRewarded2);
    formData.append("googleOtherId2", googleCreateObj?.googleOtherId2);
    formData.append("facebookAppID", googleCreateObj?.facebookAppID);
    formData.append("facebookAppSecret", googleCreateObj?.facebookAppSecret);

    // facebook

    formData.append("faceBookId1", faceBookId1 ? faceBookId1 : "");
    formData.append("faceBookId2", faceBookId2 ? faceBookId2 : "");
    formData.append(
      "faceBookAPPSecreteId1",
      faceBookAPPSecreteId1 ? faceBookAPPSecreteId1 : ""
    );
    formData.append(
      "faceBookAPPSecreteId2",
      faceBookAPPSecreteId2 ? faceBookAPPSecreteId2 : ""
    );
    formData.append("platformAPPId1", platformAPPId1 ? platformAPPId1 : "");
    formData.append("platformAPPId2", platformAPPId2 ? platformAPPId2 : "");
    formData.append("platformIns1", platformIns1 ? platformIns1 : "");
    formData.append("platformIns2", platformIns2 ? platformIns2 : "");
    formData.append(
      "platformAPPOpen1",
      platformAPPOpen1 ? platformAPPOpen1 : ""
    );
    formData.append(
      "platformAPPOpen2",
      platformAPPOpen2 ? platformAPPOpen2 : ""
    );
    formData.append("platformNative1", platformNative1 ? platformNative1 : "");
    formData.append("platformNative2", platformNative2 ? platformNative2 : "");
    formData.append("platformBanner1", platformBanner1 ? platformBanner1 : "");
    formData.append("platformBanner2", platformBanner2 ? platformBanner2 : "");
    formData.append(
      "platformRewarded1",
      platformRewarded1 ? platformRewarded1 : ""
    );
    formData.append(
      "platformRewarded2",
      platformRewarded2 ? platformRewarded2 : ""
    );
    formData.append(
      "platformOtherId1",
      platformOtherId1 ? platformOtherId1 : ""
    );
    formData.append(
      "platformOtherId2",
      platformOtherId2 ? platformOtherId2 : ""
    );
    if (googleCreateObj?.mongoId) {
      props.updateMultiAppSetting(formData, googleCreateObj?.mongoId);
    } else {
      props.addMultiAppSetting(formData);
    }
    dispatch({ type: CLOSE_DIALOGUE });
    dispatch({ type: EMPTY_APP_WISE_SETTING });
  };

  const handleClickAllSwitch = (type) => {
    props.allSwitchType(appWiseSetting?._id, type);
  };

  return (
    <div>
      <>
        <div className="row">
          <div className="d-flex justify-content-between">
            <div className="mainTitle mt-2 mb-4">Facebook Adds </div>

            <div className="switchTitleColor ">
              <span className="fw-bold fs-6 col-2">Facebook Active</span>
              <ToggleSwitch
                onClick={() => {
                  handleClickAllSwitch("isFaceBook");
                }}
                value={isFaceBook}
              />
            </div>
          </div>

          <Input
            type={`text`}
            label={`ID 1 `}
            value={faceBookId1}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setFaceBookId1(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`ID 2 `}
            value={faceBookId2}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setFaceBookId2(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`App Secrete Id 1 `}
            value={faceBookAPPSecreteId1}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setFaceBookAPPSecreteId1(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`App Secrete Id 2 `}
            value={faceBookAPPSecreteId2}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setFaceBookAPPSecreteId2(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`Platform App Id 1 `}
            value={platformAPPId1}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setPlatformAPPId1(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`Platform App Id 2 `}
            value={platformAPPId2}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setPlatformAPPId2(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`Platform INS 1 `}
            value={platformIns1}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setPlatformIns1(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`Platform INS 2 `}
            value={platformIns2}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setPlatformIns2(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`Platform App Open 1`}
            value={platformAPPOpen1}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setPlatformAPPOpen1(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`Platform App Open 2`}
            value={platformAPPOpen2}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setPlatformAPPOpen2(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`Platform Native 1 `}
            value={platformNative1}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setPlatformNative1(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`Platform Native 2 `}
            value={platformNative2}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setPlatformNative2(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`Platform Banner 1`}
            value={platformBanner1}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setPlatformBanner1(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`Platform Banner 2`}
            value={platformBanner2}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setPlatformBanner2(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`Platform Rewarded 1`}
            value={platformRewarded1}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setPlatformRewarded1(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`Platform Rewarded 2`}
            value={platformRewarded2}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setPlatformRewarded2(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`Platform Other Id 1`}
            value={platformOtherId1}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setPlatformOtherId1(e.target.value);
            }}
          />
          <Input
            type={`text`}
            label={`Platform Other Id 2`}
            value={platformOtherId2}
            newClass={`col-lg-6 col-sm-12 `}
            placeholder={``}
            onChange={(e) => {
              setPlatformOtherId2(e.target.value);
            }}
          />
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <Button
            btnName={`Submit`}
            btnIcon={`fa-solid fa-circle-arrow-right ms-2 `}
            btnColor={`btnBlackPrime`}
            style={{
              width: "140px",
              borderRadius: "6px",
              display: "flex",

              borderRadius: "6px",
              flexDirection: "row-reverse",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleSubmit}
          />
        </div>
      </>
    </div>
  );
};

export default connect(null, {
  addMultiAppSetting,
  updateMultiAppSetting,
  allSwitchType,
})(MonetizationSetting);
