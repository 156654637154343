import { useEffect, useState } from "react";
import DashboardBox from "./DashboardBox";
import {
  getDashboardUser,
  getDashboardHost,
  getUserChart,
  getRevenueChart,
  getDashboardHostFetch,
} from "../../store/dashboard/dashboard.action";
import { connect, useDispatch, useSelector } from "react-redux";
import NewTitle from "../../extra/Title";
import Analytics from "../../extra/Analytics";
import Spinner from "../../util/Spinner";
import { BeatLoader } from "react-spinners";
import bgdark from "../../../assets/images/bgdark.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Line, Pie } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
// import faker from 'faker';

// Register the scales
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const Dashboard = (props) => {
  const {
    dashBoardUser,
    dashBoardHost,
    dashBoardHostFetch,
    userChart,
    revenueChart,
  } = useSelector((state) => state.dashboard);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [revenueData, setRevenueData] = useState();
  const [userChartData, setUserChartData] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboardHostFetch(startDate, endDate));
    dispatch(getDashboardUser(startDate, endDate));
  }, [startDate, endDate]);

  const handleRefresh = () => {
    setLoading(true); // Show loader
    props
      .getDashboardHostFetch(startDate, endDate)
      ?.then(() => setLoading(false))
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    setRevenueData(revenueChart);
    setUserChartData(userChart);
  }, [revenueChart, userChart]);

  useEffect(() => {
    dispatch(getRevenueChart("revenue", startDate, endDate));
  }, [startDate, endDate]);

  useEffect(() => {
    dispatch(getUserChart("user", startDate, endDate));
  }, [startDate, endDate]);

  const labelsRevenue =
    revenueChart &&
    revenueChart.map((item) => {
      if (item && item._id) {
        const [datePart] = item._id.split("T");
        return datePart;
      } else {
        return null; // Handle the case when item or item._id is undefined or null
      }
    });

  const countsRevenue =
    revenueChart && revenueChart?.map((item) => item?.count);
  const chartDataRevenue = {
    labels: labelsRevenue,
    datasets: [
      {
        label: "Total Revenue",
        data: countsRevenue,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const optionsRevenue = {
    title: {
      display: true,
      text: "Count by Date",
    },
    maintainAspectRatio: false,
  };
  // const optionsRevenue = {
  //   title: {
  //     display: true,
  //     text: "Count by Date",
  //   },
  //   maintainAspectRatio: false,
  //   scales: {
  //     y: {
  //       ticks: {
  //         precision: 0,
  //         stepSize: 1,
  //         max: 10
  //       },
  //     },
  //   },
  // };
  // const labelsUser = userChartData && userChartData.map((item) => {

  //   return item?._id.split("T");

  // });
  const labelsUser =
    userChartData &&
    userChartData.map((item) => {
      if (item && item._id) {
        const [datePart] = item._id.split("T");
        return datePart;
      } else {
        return null; // Handle the case when item or item._id is undefined or null
      }
    });
  const countsUser = userChartData && userChartData?.map((item) => item?.count);

  const chartDataUser = {
    labels: labelsUser,
    datasets: [
      {
        label: "Total Users",
        data: countsUser,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  const optionsUser = {
    title: {
      display: true,
      text: "Count by Date",
    },
    maintainAspectRatio: false,
  };

  function numberWithCommas(number) {
    const formattedNumber = number?.toLocaleString("en-IN");
    return formattedNumber;
  }

  const totalUser = numberWithCommas(dashBoardUser?.totalUser);
  const totalRealHost = numberWithCommas(dashBoardHostFetch?.totalRealHost);
  const totalFakeHost = numberWithCommas(dashBoardUser?.totalFakeHost);
  const totalRevenue = numberWithCommas(dashBoardUser?.totalRevenue);
  const monthlyUser = numberWithCommas(dashBoardUser?.monthlyUser);

  return (
    <>
      <div className="dashboard">
        <div className="dashboardHeader primeHeader ">
          <div className="d-flex flex-wrap">
            <div className="col-9 ">
              <NewTitle name={`Dashboard`} />
            </div>
            <div className="col-md-3 justify-content-end d-flex flex-wrap pt-3 com-sm-12">
              <div className="d-flex h3 ">
                Welcome<div className="text-nowrap h3">&nbsp;Admin !</div>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="mt-2 col-md-9">
              <Analytics
                analyticsStartDate={startDate}
                analyticsStartEnd={endDate}
                analyticsStartDateSet={setStartDate}
                analyticsStartEndSet={setEndDate}
              />
            </div>
          </div>
        </div>
        <div className="dashBoardMain px-4 mt-3">
          <div className="row">
            <div
              className="adminProfileBox px-2  col-xl-3 col-lg-6 col-md-6 col-sm-12  cursor"
              onClick={() => navigate("/admin/userTable")}
            >
              <div className="dashBoxData bg-white">
                <div className="d-flex justify-content-between ms-2">
                  <div className="text-center boxCenter fw-bold h2">
                    {dashBoardUser.todayUser ? dashBoardUser.todayUser : "0"}
                  </div>
                  <div className="icon icon-sm icon-shape-small  shadow-dark text-center border-radius-xl my-auto icon-data1">
                    <i className="fa fa-user"></i>
                  </div>
                </div>
                <div className="ms-2 text-secondary">Today's User</div>
                <hr />
                <div className="ms-2 fw-bold h5">
                  Total Users:
                  <span>
                    &nbsp;&nbsp;
                    {totalUser ? totalUser : " 0 "}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="  adminProfileBox px-2   col-xl-3 col-lg-6 col-md-6 col-sm-12 cursor"
              onClick={() => navigate("/admin/realHost")}
            >
              <div className="dashBoxData bg-white">
                <div className="d-flex justify-content-between ms-2">
                  <div className="text-center boxCenter fw-bold h2">
                    {dashBoardHostFetch.todayRealHost
                      ? dashBoardHostFetch.todayRealHost
                      : "0"}
                  </div>
                  <div className="icon icon-sm icon-shape-small  shadow-dark text-center border-radius-xl my-auto icon-data2">
                    <i className="fa fa-user"></i>
                  </div>
                </div>
                <div className="ms-2 text-secondary">Today's Host</div>
                <hr />
                <div className="ms-2 fw-bold h5">
                  Total Host:
                  <span>
                    &nbsp;&nbsp;
                    {totalRealHost ? totalRealHost : "0"}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="  adminProfileBox px-2   col-xl-3 col-lg-6 col-md-6 col-sm-12 cursor"
              onClick={() => navigate("/admin/fakeHost")}
            >
              <div className="dashBoxData bg-white">
                <div className="d-flex justify-content-between ms-2">
                  <div className="text-center boxCenter fw-bold h2">
                    {dashBoardUser.todayFakeHost
                      ? dashBoardUser.todayFakeHost
                      : "0"}
                  </div>
                  <div className="icon icon-sm icon-shape-small  shadow-dark text-center border-radius-xl my-auto icon-data3">
                    <i className="fa fa-user"></i>
                  </div>
                </div>
                <div className="ms-2 text-secondary">Today's FakeHost</div>
                <hr />
                <div className="ms-2 fw-bold h5">
                  Total FakeHost:
                  <span>
                    &nbsp;&nbsp;
                    {totalFakeHost ? totalFakeHost : "0"}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="  adminProfileBox px-2  col-xl-3 col-lg-6 col-md-6 col-sm-12 cursor"
              onClick={() => navigate("/admin/planHistory")}
            >
              <div className="dashBoxData bg-white">
                <div className="d-flex justify-content-between ms-2">
                  <div className="text-center boxCenter fw-bold h2">
                    {dashBoardUser.todayRevenue
                      ? dashBoardUser.todayRevenue
                      : "0"}
                  </div>
                  <div className="icon icon-sm icon-shape-small  shadow-dark text-center border-radius-xl my-auto icon-data4">
                    <i className="fa fa-user"></i>
                  </div>
                </div>
                <div className="ms-2 text-secondary">Today's Revenue</div>
                <hr />
                <div className="ms-2 fw-bold h5">
                  Total Revenue:
                  <span>
                    &nbsp;&nbsp;
                    {totalRevenue ? totalRevenue : "0"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4 chart-box-row">
            <div className="dashChart col-lg-12 col-xl-9 col-md-12">
              <div className="charts row gx-3">
                <div className="col-md-6 col-sm-12 user-chart-col">
                  <div className="chart1">
                    <div
                      className="chartHeader h3 text-center py-2"
                      style={{ color: "#242859" }}
                    >
                      User Chart
                    </div>
                    <div
                      className="bg-white w-100 p-2"
                      style={{
                        borderRadius: "0 0 15px 15px",
                        height: "420px",
                      }}
                    >
                      {userChart || userChart?.length > 1 ? (
                        <Line data={chartDataUser} options={optionsUser} />
                      ) : (
                        <div
                          style={{
                            marginTop: "-13px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          No data available for the User chart.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 revenue-chart-col">
                  <div className="chart1 ">
                    <div
                      className="chartHeader h3 text-center py-2"
                      style={{ color: "#242859" }}
                    >
                      Revenue Chart
                    </div>
                    <div
                      className="bg-white w-100 p-2 m-auto"
                      style={{
                        borderRadius: "0 0 15px 15px",
                        height: "420px",
                      }}
                    >
                      {revenueChart || revenueChart?.length > 1 ? (
                        <Line
                          data={chartDataRevenue}
                          options={optionsRevenue}
                        />
                      ) : (
                        <div
                          style={{
                            marginTop: "-13px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          No data available for the Revenue chart.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashBoard_p2 col-lg-12 col-xl-3 col-md-6 col-sm-12 dashboard-activity  ">
              <div className="dashDataBox dashboard-activity-one col-lg-6 me-3">
                <h4
                  className="py-2 text-center m-0"
                  style={{ color: "#242859" }}
                >
                  Host Activity
                </h4>
                <div
                  className="bg-white p-3"
                  style={{ borderRadius: "0 0 15px 15px", height: "186px" }}
                >
                  <div className="p2 boxBetween ">
                    <div>Online Host</div>
                    <div>
                      {" "}
                      {dashBoardHostFetch.online
                        ? dashBoardHostFetch.online
                        : "0"}
                    </div>
                  </div>
                  <hr className="lineCss" />
                  <div className="p2 boxBetween">
                    <div>Live Host</div>
                    <div>
                      {" "}
                      {dashBoardHostFetch.live ? dashBoardHostFetch.live : "0"}
                    </div>
                  </div>
                  <hr className="lineCss" />
                  <div
                    className="p2 boxBetween"
                    style={{ borderRadius: "0 0 15px 15px" }}
                  >
                    <div>Busy Host</div>
                    <div>
                      {" "}
                      {dashBoardHostFetch.busy ? dashBoardHostFetch.busy : "0"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashDataBox mt-3 dashboard-activity-two col-lg-6 ">
                <h4
                  className="py-2 text-center m-0"
                  style={{ color: "#242859" }}
                >
                  User Activity
                </h4>
                <div
                  className="bg-white p-3"
                  style={{ borderRadius: "0 0 15px 15px", height: "186px" }}
                >
                  <div className="p2 boxBetween">
                    <div>Monthly User</div>
                    <div>{monthlyUser ? monthlyUser : "0"}</div>
                  </div>
                  <hr className="lineCss" />
                  <div className="p2 boxBetween">
                    <div>Online User</div>
                    <div>
                      {dashBoardUser.onlineUser
                        ? dashBoardUser.onlineUser
                        : "0"}
                    </div>
                  </div>
                  <hr className="lineCss" />
                  <div className="p2 boxBetween">
                    <div>Purchased User</div>
                    <div>
                      {dashBoardUser.totalPurchaseUser
                        ? dashBoardUser.totalPurchaseUser
                        : "0"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getDashboardUser,
  getDashboardHost,
  getUserChart,
  getRevenueChart,
  getDashboardHostFetch,
})(Dashboard);
