import React from "react";

import Button from "../../extra/Button";
import Title from "../../extra/Title";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setToast } from "../../util/toast";

import ToggleSwitch from "../../extra/ToggleSwitch";
import dayjs from "dayjs";
import Pagination from "../../extra/Pagination";
import { getAgency, disableSwitch } from "../../store/agency/agency.action";
import { useNavigate } from "react-router-dom";

import Searching from "../../extra/Searching";
import AgencyDialog from "./AgencyDialog";
import {
  OPEN_NOTIFICATION_DIALOGUE,
  OPEN_DIALOGUE,
} from "../../store/dialogue/dialogue.type";
import Table from "../../extra/Table";
import PlaceIcon from "@mui/icons-material/Place";
import AgencySettleMent from "../Settlement/AgencySettleMent";
import Notification from "../../Pages/Notification";
import AgencyHistory from "../Agency/AgencyHistory";
import noImage from "../../../assets/images/noimage.png"

const Agency = (props) => {
  const { agency, totalData } = useSelector((state) => state.agency);
  const {
    dialogue,
    dialogueType,
    dialogueData,
    dialogueNotification,
    dialogueNotificationType,
  } = useSelector((state) => state.dialogue);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("All");

  useEffect(() => {
    dispatch(getAgency(search, currentPage, rowsPerPage, "admin"));
  }, [search, currentPage, rowsPerPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  useEffect(() => {
    setData(agency);
  }, [agency]);

  // const copyCode = (code) => {
  //   navigator.clipboard.writeText(code);
  //   setToast("success", `Code Copy : ${code}`);
  // };

  const copyCode = async (code) => {
    try {
      await navigator.clipboard.writeText(code); // Use await to ensure clipboard action is completed.
      setToast("success", `Code Copied: ${code}`);
    } catch (error) {
      console.error("Copy failed:", error);
      setToast("error", "Copy Failed");
    }
  };

  // pagination
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  const handleClick = (userDetails) => {
    props.disableSwitch(
      userDetails,
      userDetails?.isDisable === true ? false : true
    );
  };

  const handleHost = (agencyId) => {
    navigate("/admin/agencyWiseHost", {
      state: {
        id: agencyId?._id,
        type: "agency",
        agencyName: agencyId?.name,
      },
    });
  };

  const openNotification = (row, type) => {
    dispatch({
      type: OPEN_NOTIFICATION_DIALOGUE,
      payload: {
        row: row._id,
        type: type,
      },
    });

    let dialogueData_ = {
      dialogueNotification: true,
      type,
      dialogueNotificationData: row,
    };

    localStorage.setItem(
      "dialogueNotificationData",
      JSON.stringify(dialogueData_)
    );
  };

  const handelSettlement = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };
  const handelHistory = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const handleAgencyCommission = (row) => {
    navigate("/admin/agencyCommission", { state: row });
  };

  const mapData = [
    {
      Header: "No",
      width: "20px",
      // Cell: ({ index }) => <span>{parseInt(index + 1)}</span>,
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },

    {
      Header: "Agency Name",
      body: "image",
      Cell: ({ row }) => (
        <div
          className="d-flex"
          onClick={() => handleHost(row)}
          style={{ cursor: "pointer" }}
        >
          <div className="position-relative">
            <img
              src={row?.image ? row?.image : noImage}
              style={{ borderRadius: "25px" }}
              height={50}
              width={50}
              alt=""
            />
            {row.isOnline && (
              <span
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: "green",
                  bottom: "-4px",
                  right: "3px",
                  border: "3px solid #ffff",
                }}
                className="d-block position-absolute"
              ></span>
            )}
          </div>
          <div>
            <span className="boxCenter ms-1">
              <b className="fw-bold">{row?.name}</b>
            </span>
            <div className="d-flex mt-1 ms-2">
              <PlaceIcon
                style={{ color: "rgb(225 78 132)", fontSize: "20px" }}
              />
              <p
                className="text-xs text-secondary mb-0  text-capitalize"
                style={{ fontSize: "12px" }}
              >
                {row?.countryId?.name}
              </p>
            </div>
          </div>
        </div>
      ),
    },
    // { Header: "NAME", body: "name" },
    {
      Header: "Code",
      body: "code",
      Cell: ({ row }) => (
        <span className="fw-bold">
          {row.code ? row.code : "-"}
          <span>
            <i
              class="fas fa-copy p-2 fs-6 copyCode"
              style={{ cursor: "pointer", display: "initial" }}
              onClick={() => copyCode(row?.code)}
            ></i>
          </span>
        </span>
      ),
    },
    {
      Header: "Mobile No",
      body: "mobileNumber",
      Cell: ({ row }) => <span>{row?.mobileNo ? row?.mobileNo : "-"}</span>,
    },
    {
      Header: "Email",
      body: "email",
      Cell: ({ row }) => <span>{row.email ? row.email : "-"}</span>,
    },

    {
      Header: "Current Revenue",
      sorting: { type: "server" },
      body: "currentRevenue",
      Cell: ({ row }) => (
        <span className="text-success">
          {row.currentRevenue ? row.currentRevenue : "0"}
        </span>
      ),
    },
    // {
    //   Header: "Last Revenue",
    //   body: "lastRevenue",
    //   sorting: { type: "client" },
    //   Cell: ({ row }) => <span className="text-primary">{row.lastRevenue ? row.lastRevenue : "0"}</span>,
    // },

    {
      Header: "Disable Agency",
      body: "isDisable",
      Cell: ({ row }) => (
        <ToggleSwitch value={row?.isDisable} onClick={() => handleClick(row)} />
      ),
    },
    {
      Header: "Created At",
      body: "createdAt",
      Cell: ({ row }) => (
        <span>{dayjs(row?.createdAt).format("DD MMM YYYY")}</span>
      ),
    },

    {
      Header: "Edit",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={`boxCenter userBtn text-primary fs-5`}
          btnColor={``}
          btnIcon={`far fa-edit`}
          // onClick={() => handleEdit(row)}

          onClick={() => {
            dispatch({
              type: OPEN_DIALOGUE,
              payload: { data: row, type: "Agency" },
            });
          }}
          // onClick={() => handleDelete(row.id)}
          style={{
            borderRadius: "5px",
            margin: "auto",
            width: "40px",
            // backgroundColor: "#fff",
            // color: "#160d98",
          }}
        />
      ),
    },
    // {
    //   Header: "INFO",
    //   body: "",
    //   Cell: ({ row }) => (
    //     <Button
    //       newClass={`themeFont boxCenter userBtn text-white`}
    //       btnColor={`bg-primary`}
    //       // btnName={`${row.block ? "Block" : "Unblock"}`}
    //       btnIcon={`fa-solid fa-info`}
    //       // onClick={() => handleDelete(row.id)}
    //     />
    //   ),
    // },
    {
      Header: "Settlement",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={`boxCenter userBtn text-primary fs-5`}
          btnColor={``}
          btnIcon={`fa-solid fa-folder`}
          // onClick={() => {
          //   dispatch({
          //     type: OPEN_DIALOGUE,
          //     payload: { data: row, type: "AgencySettlement" },
          //   });
          // }}
          onClick={() => handelHistory(row, "AgencySettlement")}
          style={{
            borderRadius: "5px",
            margin: "auto",
            width: "40px",
            // backgroundColor: "#fff",
            // color: "#160d98",
          }}
        />
      ),
    },
    {
      Header: "Commission (%)",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={`boxCenter userBtn text-primary fs-5`}
          btnColor={``}
          btnIcon={`fa-solid fa-ticket`}
          onClick={() => handleAgencyCommission(row, "Commission")}
          style={{
            borderRadius: "5px",
            margin: "auto",
            width: "40px",
          }}
        />
      ),
    },
    {
      Header: "Notification",
      Cell: ({ row }) => (
        <Button
          newClass={`boxCenter userBtn text-danger fs-5`}
          btnColor={``}
          btnIcon={`fa-solid fa-bell `}
          onClick={() =>
            dispatch({
              type: OPEN_NOTIFICATION_DIALOGUE,
              payload: { data: row, type: "NotificationAgency" },
            })
          }
          style={{
            borderRadius: "5px",
            margin: "auto",
            width: "40px",
          }}
        />
      ),
    },
    {
      Header: "History",
      body: "",
      Cell: ({ row }) => (
        <Button
          newClass={`boxCenter userBtn text-success fs-5`}
          btnColor={``}
          btnIcon={`fa-solid fa-history`}
          onClick={() => handelSettlement(row, "agencyHistory")}
          style={{
            borderRadius: "5px",
            margin: "auto",
            width: "40px",
            cursor: "pointer",
          }}
        />
      ),
    },

    // add more columns as needed
  ];

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };
  return (
    <>
      <div className="mainUserTable">
        {dialogue && dialogueType == "agencyHistory" && (
          <div className="mainHostInfo">
            <AgencyHistory />
          </div>
        )}
        {dialogue && dialogueType == "AgencySettlement" && (
          <div className="mainHostInfo">
            <AgencySettleMent />
          </div>
        )}
        <div
          className="userTable"
          style={{
            display: `${
              dialogueType === "AgencySettlement"
                ? "none"
                : dialogueType === "agencyHistory"
                ? "none"
                : "block"
            }`,
          }}
        >
          <div className="userHeader primeHeader">
            <div className="row">
              <div className="row">
                <Title name={`Agency`} />
              </div>
            </div>
          </div>
          <div className="primeHeader mt-4 row">
            {/* <i className="fas fa-plus fa-md"></i> */}
            <div className="col-md-6 my-2">
              <Button
                newClass={` border bgsuccess text-white bgsuccess text-white openDialogue`}
                btnIcon={`fas fa-plus fa-md`}
                btnName={`Add Agency`}
                onClick={() => {
                  dispatch({
                    type: OPEN_DIALOGUE,
                    payload: { type: "Agency" },
                  });
                }}
                // navigate("/admin/addAgency");

                // onClick={handleChangeImage}
                style={{ borderRadius: "7px" }}
              />
              {dialogue && dialogueType === "Agency" && <AgencyDialog />}
            </div>
            <div className="d-flex justify-content-end col-md-6 my-auto">
              <Searching
                type={"server"}
                data={agency}
                setData={setData}
                setSearchData={setSearch}
                onFilterData={handleFilterData}
                serverSearching={handleFilterData}
                button={true}
                column={mapData}
                placeholder={"Searching Agency..."}
              />
            </div>
          </div>

          <div className="userMain">
            <div className="tableMain">
              <Table
                data={data}
                mapData={mapData}
                serverPerPage={rowsPerPage}
                serverPage={currentPage}
                type={"server"}
              />
            </div>
            <div className="paginationFooter">
              <Pagination
                component="div"
                count={agency?.length}
                type={"server"}
                serverPerPage={rowsPerPage}
                totalData={totalData}
                serverPage={currentPage}
                onPageChange={handleChangePage}
                setCurrentPage={setCurrentPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
          <div className="userFooter primeFooter"></div>
          {dialogueNotification &&
            dialogueNotificationType === "NotificationAgency" && (
              <Notification />
            )}
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAgency, disableSwitch })(Agency);
