import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useHistory, useLocation, useNavigate } from "react-router-dom";
import IOSSwitch from "@material-ui/core/Switch";
// import { hostAccept } from "../store/hostRequest/hostRequest.action";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getHostProfile,
  blockUnblockSwitch,
  updateHostCoin,
  addTopicListHost,
  hostApproved,
  deleteTopicListHost,
} from "../../../store/host/host.action";
import EdiText from "react-editext";
import { setToast } from "../../../util/toast";
import Title from "../../../extra/Title";
import Host1 from "../../../../assets/images/male.jpg";
// import host2 from "../../../../assets/images/1.jpeg";
import host1 from "../../../../assets/images/host8.jpeg";
import host3 from "../../../../assets/images/host3.jpeg";
import host4 from "../../../../assets/images/host4.jpeg";
import host5 from "../../../../assets/images/host5.jpeg";
import host6 from "../../../../assets/images/host6.jpeg";
import male from "../../../../assets/images/malePlace.png";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@material-ui/core";
import ToggleSwitch from "../../../extra/ToggleSwitch";
import {
  CLOSE_DIALOGUE,
  OPEN_DIALOGUE,
} from "../../../store/dialogue/dialogue.type";
import Button from "../../../extra/Button";
import HostHistory from "./History/HostHistory";
import TopicListForHost from "../../../extra/TopicListForHost";

const hostAlbum = [Host1, host1, host3, host4, host5, host6];
const HostInfo = (props) => {
  const { hostProfile } = useSelector((state) => state.host);
  const { dialogueData, dialogue, dialogueType } = useSelector(
    (state) => state.dialogue
  );
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState("");
  // const [numImagesToShow, setNumImagesToShow] = useState(13);

  // topic list
  const [interestedText, setInterestedText] = useState();
  const [interestedChip, setInterestedChip] = useState([]);

  const [typeTwoText, setTypeTwoText] = useState();
  const [typeTwoChip, setTypeTwoChip] = useState([]);

  const [typeThreeText, setTypeThreeText] = useState();
  const [typeThreeChip, setTypeThreeChip] = useState([]);

  const [typeFourText, setTypeFourText] = useState();
  const [typeFourChip, setTypeFourChip] = useState([]);
  const [buttonDisable, setButtonDisable] = useState({
    interestedText: true,
    typeTwoText: true,
    typeThreeText: true,
    typeFourText: true,
  });
  const { topic, totalTopic } = useSelector((state) => state.topic);

  // topic list over state
  useEffect(() => {
    dispatch(getHostProfile(dialogueData ? dialogueData?._id : state));
  }, [dispatch, state]);

  useEffect(() => {
    window.history.pushState(null, window.location.href);
    window.addEventListener("popstate", goPrevious);
    return () => {
      window.removeEventListener("popstate", goPrevious);
    };
  }, []);

  useEffect(() => {
    setData(hostProfile);
  }, [hostProfile]);

  const handleProfile = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };
  console.log("hostProfilehostProfile", hostProfile)
  const handleHistory = (row, type) => {
    dispatch({
      type: OPEN_DIALOGUE,
      payload: {
        type: type,
        data: row,
      },
    });

    let dialogueData_ = {
      dialogue: true,
      type,
      dialogueData: row,
    };

    localStorage.setItem("dialogueData", JSON.stringify(dialogueData_));
  };

  const goPrevious = () => {

    localStorage.removeItem("dialogueData");
    localStorage.removeItem("realHostInfo");
    localStorage.removeItem("agencyWiseHost");
    // dispatch({ type: CLOSE_DIALOGUE });
    window.location.href = "/admin/realHost"
  };

  const handleClick = (hostDetail) => {
    props.blockUnblockSwitch(
      hostDetail,
      hostDetail.isBlock === true ? false : true
    );
  };
  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };
  const handleSave = (val) => {
    if (val < 0) {
      setToast("error", "Invalid Coin");
    } else {
      const coinValid = isNumeric(val);
      if (!coinValid) {
        setToast("error", "Invalid Coin");
      } else {
        props.updateHostCoin(val, data._id);
      }
    }
  };

  const handleApprovedClick = (data, type) => {
    props.hostApproved(data, type);
  };

  const handlePrevious = (url) => {
    window.open(url, "_blank");
  };

  const updateDisabledState = (fieldName, value) => {
    setButtonDisable({
      ...buttonDisable,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    updateDisabledState("interestedText", !interestedText);
  }, [interestedText]);

  useEffect(() => {
    updateDisabledState("typeTwoText", !typeTwoText);
  }, [typeTwoText]);

  useEffect(() => {
    updateDisabledState("typeThreeText", !typeThreeText);
  }, [typeThreeText]);

  useEffect(() => {
    updateDisabledState("typeFourText", !typeFourText);
  }, [typeFourText]);

  useEffect(() => {
    setInterestedChip(hostProfile && hostProfile?.interestedTopics);
    setTypeTwoChip(hostProfile && hostProfile?.iWantYour);
    setTypeThreeChip(hostProfile && hostProfile?.describeMySelf);
    setTypeFourChip(hostProfile && hostProfile?.moreInformation);
  }, [hostProfile]);

  const deleteInterestedChip = (indexData) => {
    const getId = hostProfile?._id;
    props.deleteTopicListHost(getId, 1, indexData);
  };

  const interestedChipAddData = (e) => {
    e.preventDefault();
    const topicData = { type: 1, topic: [interestedText] };
    props.addTopicListHost(hostProfile && hostProfile?._id, topicData);
    setInterestedText("");
  };

  const deleteTypeTwoChip = (indexData) => {
    const getId = hostProfile?._id;
    props.deleteTopicListHost(getId, 2, indexData);
  };
  const typeTwoChipAddData = (e) => {
    e.preventDefault();
    const topicData = { type: 2, topic: [typeTwoText] };
    props.addTopicListHost(hostProfile && hostProfile?._id, topicData);
    setTypeTwoText("");
  };

  const deleteTypeThreeChip = (indexData) => {
    const getId = hostProfile?._id;
    props.deleteTopicListHost(getId, 3, indexData);
  };
  const typeThreeChipAddData = (e) => {
    e.preventDefault();
    const topicData = { type: 3, topic: [typeThreeText] };
    props.addTopicListHost(hostProfile && hostProfile?._id, topicData);
    setTypeThreeText("");
  };

  const deleteTypeFourChip = (indexData) => {
    const getId = hostProfile?._id;
    props.deleteTopicListHost(getId, 4, indexData);
  };

  const typeFourChipAddData = (e) => {
    e.preventDefault();
    const topicData = { type: 4, topic: [typeFourText] };
    props.addTopicListHost(hostProfile && hostProfile?._id, topicData);
    setTypeFourText("");
  };

  return (
    <>
      <div className="mainHostProfile">
        <div className="hostProfile">
          <div className="hostProfileHeader primeHeader d-flex align-items-center justify-content-between">
            <div className="col-12">
              <div className="">
                <div className="col-12">
                  <Title name={`Host Profile`} />
                </div>
              </div>
              <div className="mt-4 d-flex justify-content-between">
                <h4 className="page-title mb-0  text-capitalize col-6 ">
                  <span className="text-primary">{data?.name + "'s"}</span>{" "}
                  Details
                </h4>
                <div className="ProfileBtn d-flex col-6 flex-wrap  justify-content-end">
                  {dialogueData?.type === 1 && (
                    <button
                      className="btn btn-success btn-icon px-4 mx-2"
                      onClick={() =>
                        state
                          ? handleHistory(data, "hostHistory")
                          : handleProfile(data, "hostHistory")
                      }
                    >
                      <i class="fa-solid fa-history text-white fs-6"></i>
                    </button>
                  )}

                  <button
                    className="btn btn-primary btn-icon px-4"
                    onClick={goPrevious}
                  >
                    <i class="fa-solid fa-angles-left text-white fs-6"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="hostProfileMain rounded row px-5">
            <div className=" align-items-center bg-white mainHostImage row g-0 allDetails pb-2">
              <div className="hostImageBox m-md-auto d-flex col-lg-12 col-xl-6 col-md-7 col-sm-12 px-3 justify-content-center align-items-center ">
                <div>
                  <img
                    className=" rounded profile-image mt-lg-3 mt-md-3"
                    src={data?.profilePic ? data?.profilePic : male}
                    width={350}
                    onError={(e) => {
                      e.target.src = male;
                    }}
                    height={280}
                  />
                  <tr className="row ">
                    <div className="col-12 text-center ms-4 pt-4 wrapper">
                      {/* <FormControl className="mb-0">
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={data?.isApproved}
                        >
                          <FormControlLabel
                            value="Approved"
                            checked={data?.isApproved ? true : false}
                            control={<Radio />}
                            onChange={() => {
                              handleApprovedClick(data, "Approved");
                            }}
                            label="Approved"
                          />
                          <FormControlLabel
                            value="Rejected"
                            // onClick={handleVideo}
                            checked={data?.isRejected ? true : false}
                            onChange={() => {
                              handleApprovedClick(data, "Rejected");
                            }}
                            control={<Radio />}
                            label="Rejected"
                          />
                        </RadioGroup>
                      </FormControl> */}
                    </div>
                  </tr>
                </div>
              </div>

              <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12 m-auto">
                <div className="allDetails bg-white">
                  <table className="uTable w-100">
                    <tr>
                      <td> Name</td>
                      <td>:</td>
                      <td>{data?.name}</td>
                    </tr>
                    <tr>
                      <td>Unique Id</td>
                      <td>:</td>
                      <td>{data?.uniqueId}</td>
                    </tr>
                    {/* {dialogueData?.type === 1 && (
                      <tr>
                        <td>Password</td>
                        <td>:</td>
                        <td>{data?.password}</td>
                      </tr>
                    )} */}
                    <tr>
                      <td>Bio</td>
                      <td>:</td>
                      <td>{data?.bio ? data?.bio : "Hi Dear"}</td>
                    </tr>
                    <tr>
                      <td width="110px">Coin</td>
                      <td width="50px">:</td>
                      <td>
                        <EdiText
                          type="number"
                          value={data?.coin}
                          onSave={handleSave}
                        />
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Call Charge</td>
                      <td>:</td>
                      <td>{data?.callCharge}</td>
                    </tr> */}
                    {dialogueData?.type === 2 && (
                      <tr>
                        <td>Video</td>
                        <td>:</td>
                        <td>
                          <a
                            href={data?.video}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {data?.video}
                          </a>
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </div>
            <div className="mainHostData p-0">
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xl-6 p-0">
                    <div className="allDetails bg-white px-4 py-4 shadow-sm mt-6">
                      <table className="uTable w-100">
                        <tr>
                          <td>Status</td>
                          <td>:</td>
                          <td>
                            {data?.isOnline === true ? (
                              <div
                                className="bg-success py-1 px-2 d-inline-block text-white"
                                style={{
                                  fontSize: "11px",
                                  borderRadius: "10px",
                                }}
                              >
                                Online
                              </div>
                            ) : (
                              <div
                                className="bg-danger py-1 px-2 d-inline-block text-white"
                                style={{
                                  fontSize: "11px",
                                  borderRadius: "5px",
                                }}
                              >
                                Offline
                              </div>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="py-0">Block</td>
                          <td className="py-0">:</td>
                          <td className="py-0">
                            {/* <IOSSwitch
                          checked={data?.isBlock == true ? true : false}
                          color="primary"
                          onClick={() => handleClick(data)}
                        /> */}
                            <ToggleSwitch
                              value={data?.isBlock}
                              onClick={() => handleClick(data)}
                            />
                          </td>
                        </tr>
                        {/* <tr>
                          <td>Gender</td>
                          <td>:</td>
                          <td>{data?.gender}</td>
                        </tr> */}
                        <tr>
                          <td>Age</td>
                          <td>:</td>
                          <td>{data?.age ? data?.age : "-"}</td>
                        </tr>
                        {/* <tr>
                          <td>Country</td>
                          <td>:</td>
                          <td>{data?.countryId?.name}</td>
                        </tr> */}
                        <tr>
                          <td>Last Login</td>
                          <td>:</td>
                          <td>{data?.lastLogin}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xl-6">
                    <div className="allDetails bg-white px-4 shadow-sm mt-3">
                      <table className="uTable w-100 ">
                        <tr className="row">
                          <div style={{ overflowY: "auto" }}>
                            {/* <div
                            className="m-4 row   allDetails shadow-sm  bg-white pb-4"
                            style={{ minHeight: "345px",height:"345px",overflowY:"auto" }}
                          >
                            {hostProfile?.image?.length > 0
                              ? hostProfile?.image?.map((image) => {
                                  return (
                                    <>
                                      <div className="col-lg-4 col-md-4 col-sm-6  py-3 hostImages boxCenter">
                                        <img
                                          src={image}
                                          alt=""
                                          draggable="false"
                                          className="mx-auto myImage"
                                          onClick={() => handlePrevious(image)}
                                        />
                                      </div>
                                    </>
                                  );
                                })
                              : hostAlbum.map((image) => {
                                  return (
                                    <>
                                      <div className="col-lg-4 col-md-4 col-sm-6  py-4 hostImages">
                                        <img
                                          src={image}
                                          alt=""
                                          draggable="false"
                                          style={{
                                            // width: "130px",
                                            // height: "130px ",
                                            objectFit: "cover",
                                            display: "block",
                                            // borderRadius: "10px",
                                          }}
                                          className="mx-auto myImage"
                                          onClick={() => handlePrevious(image)}
                                        />
                                      </div>
                                    </>
                                  );
                                })}
                          </div> */}

                            <div
                              className="row allDetails shadow-sm bg-white mt-4"
                              style={{
                                minHeight: "315px",
                                height: "315px",
                                overflowY: "auto",
                              }}
                            >

                              {hostProfile?.image?.map((image, index) => (
                                <div key={index} className="py-3 col-lg-4 col-md-4 col-sm-6 boxCenter">
                                  <div className="imageGridInfo">
                                    {/* Image */}
                                    <div className="imageWrapper">
                                      <img
                                        src={image?.url || Host1}
                                        alt={`Image ${index}`}
                                        draggable="false"
                                        className="mx-auto myImage"
                                        style={{ maxHeight: "100px", maxWidth: "100px" }}
                                        onError={(e) => {
                                          e.target.src = Host1; // fallback image in case of error
                                        }}
                                        onClick={() => handlePrevious(image)} // assuming this is your click handler
                                      />
                                    </div>

                                    {/* Status */}
                                    <div className="statusWrapper">
                                      <span className="statusLabelInfo">
                                        {image?.verificationStatus === 1
                                          ? <span style={{ color: "#FBBC05" }}>Pending</span>
                                          : image?.verificationStatus === 2
                                            ? <span style={{ color: "#198754" }}>Approved</span>
                                            : <span style={{ color: "red" }}>Rejected</span>}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex p-0 mt-4">
              {/* <div className="interested-topics  col-md-6  col-lg-3 col-sm-12  ms-2"> */}
                {/* <TopicListForHost
                  label={"Interested topics"}
                  handleDeleteChip={deleteInterestedChip}
                  handleAddData={interestedChipAddData}
                  setAddChipData={setInterestedText}
                  addChipData={interestedText}
                  addChip={interestedChip}
                  disabled={buttonDisable.interestedText}
                />
              </div> */}
              <div className="col-md-6  col-lg-3 col-sm-12 ms-2">
                <TopicListForHost
                  label={"I want you're"}
                  handleDeleteChip={deleteTypeTwoChip}
                  handleAddData={typeTwoChipAddData}
                  setAddChipData={setTypeTwoText}
                  addChipData={typeTwoText}
                  addChip={typeTwoChip}
                  disabled={buttonDisable.typeTwoText}
                />
              </div>

              {/* <div className="col-md-6  col-lg-3 col-sm-12 ms-2">
                <TopicListForHost
                  label={"Describe myself"}
                  handleDeleteChip={deleteTypeThreeChip}
                  handleAddData={typeThreeChipAddData}
                  setAddChipData={setTypeThreeText}
                  addChipData={typeThreeText}
                  addChip={typeThreeChip}
                  disabled={buttonDisable.typeThreeText}
                />
              </div>
              <div className="col-md-6  col-lg-3 col-sm-12  ms-2">
                <TopicListForHost
                  label={"More Information"}
                  handleDeleteChip={deleteTypeFourChip}
                  handleAddData={typeFourChipAddData}
                  setAddChipData={setTypeFourText}
                  addChipData={typeFourText}
                  addChip={typeFourChip}
                  disabled={buttonDisable.typeFourText}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  getHostProfile,
  blockUnblockSwitch,
  updateHostCoin,
  hostApproved,
  addTopicListHost,
  deleteTopicListHost,
})(HostInfo);
