import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { getFakeHostForStory } from "../../store/FakeHost/fakeHost.action";
import { createFakeStory } from "../../store/fakeStory/fakeStory.action";

const FakeStoryDialog = (props) => {
  const dispatch = useDispatch();
  const { fakeHost } = useSelector((state) => state.fakeHost);

  const [mongoId, setMongoId] = useState("");
  const [hostType, setHostType] = useState("");
  const [type, setType] = useState();
  // story
  const [storyType, setStoryType] = useState(2);
  const [mediaPath, setMediaPath] = useState("");
  const [mediaFile, setMediaFile] = useState(null);

  // const [mainType, setMainType] = useState();

  // preview
  const [previewType, setPreviewType] = useState(2);
  const [previewPath, setPreviewPath] = useState("");
  const [previewFile, setPreviewFile] = useState(null);
  const [error, setError] = useState({
    story: "",
    media: "",
    preview: "",
    hostType: "",
  });

  useEffect(() => {
    dispatch(getFakeHostForStory());
  }, [dispatch]);

  // story

  const handleInput = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setError({ ...error, media: "Story Is Required" });
    } else {
      setError({ ...error, media: "" });
      setMediaFile(file);
      setMediaPath(URL.createObjectURL(file));
    }
  };

  // preview
  const handleInputPreview = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setError({ ...error, preview: "Preview Is Required" });
    } else {
      setError({ ...error, preview: "" });
      setPreviewFile(file);
      setPreviewPath(URL.createObjectURL(file));
    }
  };

  const isValidImageUrl = (url) => {
    // Regular expression for URL validation
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    // Regular expression for image file extensions
    const imageAndVideoExtensions =
      /\.(jpg|jpeg|png|gif|webp|mp4|mov|avi|wmv)$/i;
    return urlPattern.test(url) && imageAndVideoExtensions.test(url);
  };

  const handleSubmit = () => {
    if (
      !hostType ||
      (storyType === 1 && mediaFile?.length === 0) ||
      (previewType === 1 && previewFile?.length === 0) ||
      (storyType === 2 && !isValidImageUrl(mediaPath)) ||
      (previewType === 2 && !isValidImageUrl(previewPath))
    ) {
      let error = {};
      if (!hostType) error.hostType = "Host is required";
      if (!mediaPath) error.media = "Story is Required";
      if (mediaFile?.length === 0) error.media = "Story is Required";
      if (!previewPath) error.preview = "Preview is Required";
      if (previewFile?.length === 0) error.preview = "Preview is Required";
      if (!isValidImageUrl(mediaPath)) error.media = "Invalid Image URL";
      if (!isValidImageUrl(previewPath)) error.preview = "Invalid Image URL";
      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("hostId", hostType);
      formData.append("storyType", storyType);
      formData.append("previewType", previewType);
      if (storyType === 2) {
        formData.append("story", mediaPath);
      } else {
        formData.append("story", mediaFile);
      }

      if (previewType === 2) {
        formData.append("preview", previewPath);
      } else {
        formData.append("preview", previewFile);
      }

      let checkVideoOrImage;
      if (storyType === 2) {
        checkVideoOrImage = checkFile(mediaPath);
      } else {
        checkVideoOrImage = checkFile(mediaFile);
      }
      console.log("checkImageOrVideo", checkVideoOrImage);

      // formData.append("type", 2);

      props.createFakeStory(formData);
      handleClosed();
    }
  };
  const getFileType = (file) => {
    if (!file || !file.type) {
      return "unknown";
    }

    if (file.type.startsWith("image/")) {
      return "image";
    } else if (file.type.startsWith("video/")) {
      return "video";
    } else {
      return "unknown";
    }
  };

  const checkFile = (data) => {
    if (data) {
      const fileType = getFileType(data);

      switch (fileType) {
        case "image":
          return "image";
        // Handle image file logic
        case "video":
          return "video";
        // Handle video file logic
        case "unknown":
          console.log("File is neither an image nor a video");
          // Handle unknown file type logic
          break;
        default:
          break;
      }
    }
  };

  const handleClosed = () => {
    localStorage.removeItem("dialogueData");
    dispatch({ type: CLOSE_DIALOGUE });
  };

  useEffect(() => {
    console.log("previewPath", previewPath);
    console.log("previewPath", mediaPath);
  }, [previewPath, mediaPath]);

  return (
    <>
      <div className="mainDialogue fade-in">
        <div
          className="Dialogue"
          style={{ overflow: "auto", maxHeight: "85vh", top: "53%" }}
        >
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">Add Story</div>
            <div
              className="closeBtn closeDialogue"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i className="fa-solid fa-xmark ms-2"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="row">
              <div className="col-12">
                <label className="styleForTitle mb-2 text-dark">Host</label>
                <select
                  productName="type"
                  className="form-control form-control-line"
                  id="type"
                  value={hostType}
                  onChange={(e) => {
                    setHostType(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        hostType: "Host is Required !",
                      });
                    } else {
                      return setError({
                        ...error,
                        hostType: "",
                      });
                    }
                  }}
                >
                  <option value="" disabled selected>
                    --select host--
                  </option>
                  {fakeHost?.map((data) => {
                    return <option value={data?._id}>{data.name}</option>;
                  })}
                </select>
                {error.hostType && (
                  <div className="ml-2 mt-1">
                    {error.hostType && (
                      <div className="pl-1 text__left">
                        <span className="text-danger">{error.hostType}</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 d-flex mt-4">
                <label className="text-gray">Story Type : </label>
                <div className="col-3 d-flex align-items-center">
                  <Input
                    id={`story`}
                    type={`radio`}
                    value={`1`}
                    name={`storyType`}
                    newClass={`me-2 mb-0`}
                    onClick={() => {
                      setStoryType(1);
                    }}
                    checked={storyType === 1}
                  />
                  <label className="form-check-label" htmlFor="image">
                    File
                  </label>
                </div>
                <div className="col-3 d-flex align-items-center">
                  <Input
                    id={`story`}
                    type={`radio`}
                    value={`2`}
                    name={`storyType`}
                    newClass={`me-2 mb-0`}
                    onClick={() => {
                      setStoryType(2);
                    }}
                    checked={storyType === 2}
                  />
                  <label className="form-check-label" htmlFor="linkImage">
                    Link
                  </label>
                </div>
              </div>
              <div className="row mt-2 align-items-center">
                <div className={storyType === 1 ? "col-12" : "d-none"}>
                  <div className="form-group image-input story">
                    <label className="mb-2 text-gray">File</label>
                    <Input
                      type={`file`}
                      accept={`video/*,image/*`}
                      onChange={handleInput}
                      newClass={`col-md-12 d-flex align-self-end`}
                    />
                    {storyType === 1 && (
                      <div>
                        <iframe
                          src={mediaPath}
                          id="Iframe"
                          title="media"
                          style={{
                            width: "100px",
                            height: "100px",
                            border: "none",
                            overflow: "hidden",
                          }}
                        />
                      </div>
                    )}
                    {error.media && (
                      <div className="ml-2 mt-1">
                        {error.media && (
                          <div className="pl-1 text__left">
                            <span className="text-danger">{error.media}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className={storyType === 2 ? "col-12" : "d-none"}>
                  <div className="form-group story">
                    <label className="mb-2 text-gray">Media Link</label>
                    <Input
                      placeholder={`link`}
                      id={`link`}
                      type={`text`}
                      value={mediaPath}
                      onChange={(e) => {
                        setMediaPath(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            media: `Story Link Is Required`,
                          });
                        } else if (!isValidImageUrl(e.target.value)) {
                          return setError({
                            ...error,
                            media: `Story Link Is InValid`,
                          });
                        } else {
                          return setError({
                            ...error,
                            media: "",
                          });
                        }
                      }}
                    />

                    {storyType === 2 && (
                      <div>
                        <iframe
                          src={mediaPath}
                          id="Iframe"
                          style={{
                            width: "100px",
                            height: "100px",
                            border: "none",
                            overflow: "hidden",
                          }}
                          title="media"
                        />
                      </div>
                    )}
                    {error.media && (
                      <div className="ml-2 mt-1">
                        {error.media && (
                          <div className="pl-1 text__left">
                            <span className="errorMessage">{error.media}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 d-flex mt-4">
                <label className="text-gray">Preview Type : </label>
                <div className="col-3 d-flex align-items-center">
                  <Input
                    id={`preview`}
                    type={`radio`}
                    value={`1`}
                    name={`previewType`}
                    newClass={`me-2 mb-0`}
                    onClick={() => {
                      setPreviewType(1);
                    }}
                    checked={previewType === 1}
                  />
                  <label className="form-check-label" htmlFor="image">
                    File
                  </label>
                </div>
                <div className="col-3 d-flex align-items-center">
                  <Input
                    id={`preview`}
                    type={`radio`}
                    value={`2`}
                    name={`previewType`}
                    newClass={`me-2 mb-0`}
                    onClick={() => {
                      setPreviewType(2);
                    }}
                    checked={previewType === 2}
                  />
                  <label className="form-check-label" htmlFor="linkImage">
                    Link
                  </label>
                </div>
              </div>
              <div className="row mt-2 align-items-center">
                <div className={previewType === 1 ? "col-12" : "d-none"}>
                  <div className="form-group image-input story">
                    <label className="mb-2 text-gray">File</label>
                    <Input
                      type={`file`}
                      accept={`image/*`}
                      onChange={handleInputPreview}
                      newClass={`col-md-12 d-flex align-self-end`}
                    />
                    {previewPath === 1 && (
                      <div>
                        <img
                          src={previewPath}
                          id="Iframe"
                          style={{
                            width: "100px",
                            height: "100px",
                            border: "none",
                            overflow: "hidden",
                          }}
                          title="media"
                        />
                      </div>
                    )}
                    {error.preview && (
                      <div className="ml-2 mt-1">
                        {error.preview && (
                          <div className="pl-1 text__left">
                            <span className="text-danger">{error.preview}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className={previewType === 2 ? "col-12" : "d-none"}>
                  <div className="form-group story">
                    <label className="mb-2 text-gray">Media Link</label>
                    <Input
                      placeholder={`link`}
                      id={`link`}
                      type={`text`}
                      value={previewPath}
                      onChange={(e) => {
                        setPreviewPath(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            preview: `Preview Link Is Required`,
                          });
                        } else if (!isValidImageUrl(e.target.value)) {
                          return setError({
                            ...error,
                            preview: `Preview Link Is InValid`,
                          });
                        } else {
                          return setError({
                            ...error,
                            preview: "",
                          });
                        }
                      }}
                    />
                    {previewPath === 2 && (
                      <div>
                        <img
                          src={previewPath}
                          style={{
                            width: "100px",
                            height: "100px",
                            border: "none",
                            overflow: "hidden",
                          }}
                          title="media"
                        />
                      </div>
                    )}
                    {error.preview && (
                      <div className="ml-2 mt-1">
                        {error.preview && (
                          <div className="pl-1 text__left">
                            <span className="errorMessage">
                              {error.preview}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="dialogueFooter">
              <div className="dialogueBtn">
                {mongoId ? (
                  <Button
                    btnName={`Update`}
                    btnColor={`btnBlackPrime text-white`}
                    style={{ borderRadius: "5px", width: "80px" }}
                    newClass={`me-2`}
                    onClick={handleSubmit}
                  />
                ) : (
                  <Button
                    btnName={`Submit`}
                    btnColor={`btnBlackPrime text-white`}
                    style={{ borderRadius: "5px", width: "80px" }}
                    newClass={`me-2`}
                    onClick={handleSubmit}
                  />
                )}

                <Button
                  btnName={`Close`}
                  btnColor={`bg-danger text-white`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  onClick={handleClosed}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getFakeHostForStory, createFakeStory })(
  FakeStoryDialog
);
