import axios from "axios";
import { setToast } from "../../util/toast";
import {
    CREATE_NEW_LEVEL,
    DELETE_LEVEL,
    GET_LEVEL,
    UPDATE_NEW_LEVEL,
    VIP_SWITCH,
} from "./level.type";
import { apiInstanceFetch } from "../../util/api";

export const getVipLevel = () => (dispatch) => {
    apiInstanceFetch
        .get(`admin/vipLevel/fetchVipLevelByAdmin`)
        .then((res) => {
            console.log("ressssss>>", res)
            if (res.staus) {
                dispatch({ type: GET_LEVEL, payload: res.vipLevel });
            } else {
                setToast("error", res.message);
            }
        })
        .catch((error) => setToast("error", error.message));
};


export const createVipLevel = (data) => (dispatch) => {
    return axios
        .post(`admin/vipLevel/createVipLevel`, data) // Return the promise here
        .then((res) => {
            if (res.data.status) {
                dispatch({ type: CREATE_NEW_LEVEL, payload: res.data.vipLevel });
                return res.data;  // Return the response data for further chaining
            } else {
                throw new Error(res.data.message); // Reject the promise
            }
        })
        .catch((error) => {
            throw error; // Propagate the error for chaining
        });
};

export const updateVipLevel = (data) => (dispatch) => {
    return axios
        .patch(`admin/vipLevel/updateVipLevel`, data) // Return the promise here
        .then((res) => {
            if (res.data.status) {
                dispatch({ type: UPDATE_NEW_LEVEL, payload: res.data.vipLevel });
                return res.data;  // Return the response data for further chaining
            } else {
                throw new Error(res.data.message); // Reject the promise
            }
        })
        .catch((error) => {
            throw error; // Propagate the error for chaining
        });
};


