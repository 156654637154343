import React, { useEffect, useState } from "react";
import Title from "../../extra/Title";
import Table from "../../extra/Table";
import { getPurchaseHistory } from "../../store/coinPlan/coinPlan.action";
import { connect, useDispatch, useSelector } from "react-redux";
import Pagination from "../../extra/Pagination";
import dayjs from "dayjs";
import ToggleSwitch from "../../extra/ToggleSwitch";
import Searching from "../../extra/Searching";
import { useNavigate } from "react-router-dom";

const PurchaseHistory = (props) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState("createdAt");
  const [type, setType] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const { history, total, totalDollar } = useSelector(
    (state) => state.coinPlan
  );

  useEffect(() => {
    dispatch(getPurchaseHistory(search, currentPage, rowsPerPage , sort , type))
  }, [search, currentPage, rowsPerPage, sort , type]);
  useEffect(() => {
    setData(history);
  }, [history]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handelUserProfile = (userId) => {
    console.log('userId :>> ', userId);
    navigate("/admin/userInfo", {
      state: userId,
    });
  };
  const handleChildValue = (value) => {
    setType(type === -1 ? 1 : -1);
    setSort(value);
    console.log("data",value);
  }

  const userPurchase = [
    {
      Header: "No",
      width: "20px",
      // Cell: ({ index }) => <span>{index + 1}</span>,
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    {
      Header: "User Name",
      body: "name",
      Cell: ({ row }) => (
        <span
          // onClick={() => handelUserProfile(row , "userInfo")}
          className="text-capitalize"
          // style={{ cursor: "pointer" }}
        >
          {row.userId?.name}
        </span>
      ),
    },
    {
      Header: "Unique Id",
      body: "uniqueId",
      Cell: ({ row }) => <span>{row?.userId?.uniqueId}</span>,
    },
    {
      Header: "Order Id",
      body: "orderId",
      Cell: ({ row }) => <span>{row?.orderId ? row.orderId : "-"}</span>,
    },
    {
      Header: "App Name",
      body: "appName",
      Cell: ({ row }) => <span>{row?.userId?.packageName?.appName || "-"}</span>,

    },
    {
      Header: "Coin",
      body: "uCoin",
      sorting: { type: "server" },
      Cell: ({ row }) => <span className="text-success">{row?.uCoin}</span>,
    },
    {
      Header: "INR(₹)",
      body: "rupee",
      sorting: { type: "server" },
      Cell: ({ row }) => <span className="textPink">{row?.planId?.rupee}</span>,
    },
    {
      Header: "USD($)",
      body: "dollar",
      sorting: { type: "server" },
      Cell: ({ row }) => <span className="text-primary">{row?.planId?.dollar}</span>,
    },
    {
      Header: "Plan Type",
      body: "description",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.description}</span>
      ),
    },
    {
      Header: "Country",
      body: "country",
      Cell: ({ row }) => (
        <span>{row.userId.country ? row.userId.country : "-"}</span>
      ),
    },
    {
      Header: "Date",
      body: "date",
      Cell: ({ row }) => <span>{dayjs(row?.date).format("DD MMM YYYY")}</span>,
    },
    {
      Header: "Payment Gateway",
      body: "paymentGateway",
      Cell: ({ row }) => (
        <spam className=" text-primary">
          {row?.paymentGateway ? row.paymentGateway : "-"}
        </spam>
      ),
    },

    // add more columns as needed
  ];

  return (
    <>
      <div className="mainPurchaseTable">
        <div className="purchasePurchase">
          <div className="purchaseHeader primeHeader">
            <div className="row">
              <Title name={`Purchase History`} />
            </div>
            <div className="row justify-content-end">
              <div className="col-lg-6 col-md-6  my-auto p-3 row">
                <span className="col-lg-3 totalTex text-center">Total USD($)</span>
                <span className="totalDollar col-lg-3 text-center">
                  <span className="fw-bold">{totalDollar ? totalDollar : "0"} </span>
                  <span className="text-primary fw-bold">$</span>
                </span>
              </div>
        
              <div className="col-lg-6 d-flex align-items-center justify-content-end px-0">
                <Searching
                  type={"server"}
                  data={history}
                  setData={setData}
                  setSearchData={setSearch}
                  onFilterData={handleFilterData}
                  serverSearching={handleFilterData}
                  button={true}
                  column={userPurchase}
                  placeholder={"Searching Purchase History..."}
                />
              </div>
            </div>
          </div>
          <div className="userMain">
            <div className="tableMain mt-3">
              <Table
                data={data}
                mapData={userPurchase}
                serverPerPage={rowsPerPage}
                serverPage={currentPage}
                type={"server"}
                onChildValue={handleChildValue}
              />
              </div>
              <div className="paginationFooter">
              <Pagination
                component="div"
                count={history?.length}
                type={"server"}
                serverPerPage={rowsPerPage}
                totalData={total}
                serverPage={currentPage}
                onPageChange={handleChangePage}
                setCurrentPage={setCurrentPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
            </div>
          
          <div className="purchaseFooter primeFooter"></div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getPurchaseHistory })(PurchaseHistory);
