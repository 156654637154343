import axios from "axios";
import * as ActionType from "./admin.type";
import { Navigate, useNavigate } from "react-router-dom";
import { setToast } from "../../util/toast";
import { apiInstanceFetch } from "../../util/api";
export const loginAdmin = (login) => (dispatch) => {
  axios
    .post("admin/login", login)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: ActionType.LOGIN_ADMIN, payload: res.data.token });
        setToast("success", "Login Successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};

export const getProfile = () => (dispatch) => {
  apiInstanceFetch
    .get("admin/profile")
    .then((res) => {
      if (res.status) {
        dispatch({ type: ActionType.UPDATE_PROFILE, payload: res.admin });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

export const changePassword = (data) => (dispatch) => {
  axios
    .put("admin/updateAdminPassword", data)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "Password Changed Successfully.");
        setTimeout(() => {
          dispatch({ type: ActionType.UNSET_ADMIN });
          window.location.href = "/";
        }, [3000]);
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
export const profileUpdate = (formData) => (dispatch) => {
  axios
    .patch("admin/update", formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_PROFILE,
          payload: res.data.admin,
        });
        setToast("success", "Admin update Successfully");
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};
