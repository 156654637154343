import { Link, useNavigate } from "react-router-dom";
import Table from "../../extra/Table";
import Button from "../../extra/Button";
import Title from "../../extra/Title";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ToggleSwitch from "../../extra/ToggleSwitch";
import Searching from "../../extra/Searching";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Pagination from "../../extra/Pagination";
import {
  getAgencySettlement,
  paidSettlement,
} from "../../store/settlement/settlement.action";
import SettlementActionDialogue from "./SettlementActionDialogue";
import BonusInfo from "./BonusInfo";

const SettlementPending = (props) => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const { agencySettlement, historyCount } = useSelector(
    (state) => state.settlement
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("ALL");
  const [type, setType] = useState(1);
  const [sort, setSort] = useState("");
  const [typeSort, setTypeSort] = useState(0);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  useEffect(() => {
    dispatch(
      getAgencySettlement(
        startDate,
        endDate,
        currentPage,
        rowsPerPage,
        search === "" ? "ALL" : search,
        type,
        sort,
        typeSort
      )
    );
  }, [startDate, endDate, currentPage, rowsPerPage, search, sort, typeSort]);

  useEffect(() => {
    setData(agencySettlement);
  }, [agencySettlement]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handlePayment = (id) => {
    props.paidSettlement(id);
  };

  const handleLoadSettlementPage = (data) => {
    const state = {
      id: data?.agencyId?._id,
      type: "custom",
      startDate: data?.startDate,
      endDate: data?.endDate,
      agencyName: data?.agencyId?.name,
    };
    navigate("/admin/agencySettlement", { state: state });
  };
  const mapData = [
    {
      Header: "No",
      width: "20px",
      // Cell: ({ index }) => <span>{index + 1}</span>,
      Cell: ({ index }) => (
        <span>{(currentPage - 1) * rowsPerPage + parseInt(index) + 1}</span>
      ),
    },
    { Header: "	Agency Code", body: "agencyId.code" },
    { Header: "Agency Name", body: "agencyId.name" },
    { Header: "Start Date ", body: "startDate" },
    {
      Header: "End Date",
      Cell: ({ row }) => <span className="text-nowrap">{row.endDate}</span>,
    },
    {
      Header: "Revenue",
      body: "coinEarned",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span
          onClick={() => handleLoadSettlementPage(row)}
          className="cursor text-primary fw-bold"
        >
          {row?.coinEarned}
        </span>
      ),
    },
    {
      Header: "commission",
      body: "commissionCoinEarned",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span>
          {row?.commissionCoinEarned} &nbsp;
          <span className="text-success">
            ({row?.agencyCommisionPercentage + "%"})
          </span>
        </span>
      ),
    },
    {
      Header: "Total Revenue",
      body: "totalCoinEarned",
      sorting: { type: "server" },
    },
    {
      Header: "Penalty/Bonus",
      body: "penltyOrBonus",
      sorting: { type: "client" },
      Cell: ({ row }) => <span onClick={() => {
        dispatch({
          type: OPEN_DIALOGUE,
          payload: { type: "bonusInfo", data: row },
        });
      }} className={`cursor ${row?.bonusOrPenltyAmount > 0 ? "text-success" : row?.bonusOrPenltyAmount === 0 ? "text-dark" : "text-danger"}`}>{row?.bonusOrPenltyAmount}</span>

    },
    {
      Header: "Final",
      body: "amount",
      sorting: { type: "server" },
      Cell: ({ row }) => (
        <span
          onClick={() => handleLoadSettlementPage(row)}
          className="cursor textPink boxCenter fw-bold "
        >
          {row?.amount}
        </span>
      ),
    },
    {
      Header: "Amount",
      Cell: ({ row }) => (
        <span
          onClick={() => handleLoadSettlementPage(row)}
          className="cursor text-primary"
        >
          {row.dollar + "$"}
        </span>
      ),
      sorting: { type: "server" },
    },
    {
      Header: "Pay",
      body: "pay",
      Cell: ({ row }) => (
        <Button
          newClass={` boxCenter userBtn text-white fs-6 border`}
          btnColor={`bg-primary`}
          btnName={`Pay`}
          // btnIcon={`fa-solid fa-info`}
          onClick={() => handlePayment(row._id)}
          style={{ borderRadius: "5px", margin: "auto", width: "100px" }}
        />
      ),
    },
    {
      Header: "Action",
      body: "action",
      Cell: ({ row }) => (
        <div>
          <Button
            newClass={` boxCenter userBtn text-white fs-6 border`}
            btnColor={`bgPink`}
            btnName={`Action`}
            // btnIcon={`fa-solid fa-info`}
            // onClick={() => handleAction(row, "hostInfo")}
            onClick={() => {
              dispatch({
                type: OPEN_DIALOGUE,
                payload: {
                  data: row,
                  id: row._id,
                  type: "settlementActionDialogue",
                },
              });
            }}
            style={{ borderRadius: "5px", margin: "auto", width: "100px" }}
          />
        </div>
      ),
    },
    // add more columns as needed
  ];

  const handleChildValue = (value) => {
    setTypeSort(typeSort === -1 ? 1 : -1);
    setSort(value);
  };
  return (
    <>
      <div className="mainSettlementTable">
        {dialogue && dialogueType === "settlementActionDialogue" && (
          <SettlementActionDialogue />
        )}
        {dialogue && dialogueType === "bonusInfo" && <BonusInfo />}
        <div className="userTable">
          <div className="userHeader primeHeader">
            <div className="row">
              <Title name={`Pending Settlement`} />
            </div>
            <div className="d-flex justify-content-end col-md-12 ">
              <div className="col-6 my-3">
                <Searching
                  type={"server"}
                  data={agencySettlement}
                  setData={setData}
                  setSearchData={setSearch}
                  onFilterData={handleFilterData}
                  serverSearching={handleFilterData}
                  button={true}
                  column={mapData}
                  placeholder={"Searching User..."}
                />
              </div>
            </div>
          </div>
          <div className="userMain">
            <div className="tableMain ">
              <Table
                data={data}
                mapData={mapData}
                serverPerPage={rowsPerPage}
                serverPage={currentPage}
                type={"server"}
                onChildValue={handleChildValue}
              />
            </div>
            <div className="paginationFooter">
              <Pagination
                component="div"
                count={data?.length}
                serverPage={currentPage}
                type={"server"}
                onPageChange={handleChangePage}
                serverPerPage={rowsPerPage}
                totalData={historyCount}
                setCurrentPage={setCurrentPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </div>
          <div className="userFooter primeFooter"></div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAgencySettlement, paidSettlement })(
  SettlementPending
);
