import React, { useEffect } from "react";
import { useState } from "react";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import Title from "../../extra/Title";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import {
    deleteBanner,
    getBanner,
    handleVIPSwitch,
} from "../../store/vipBanner/banner.action";
import ToggleSwitch from "../../extra/ToggleSwitch";
import { baseURL } from "../../util/config";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import VipLevelDialogue from "./VipLevelDialogue";
import { getVipLevel } from "../../store/VipLevel/level.action";
import { MdModeEdit } from "react-icons/md";
import Male from "../../../assets/images/male.jpg";

import Searching from "../../extra/Searching";
const VipLevel = (props) => {
    const { vipLevel } = useSelector((state) => state.vipLevel);
    const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState("")
    const [data, setData] = useState([]);
    console.log("vipLevel", vipLevel);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getVipLevel());
    }, [dispatch]);

    useEffect(() => {
        setData(vipLevel);
    }, [vipLevel]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event, 10));
        setPage(0);
    };

    const handleOpenImage = (url) => {
        window.open(url, "_blank");
    };

    const mapData = [
        {
            Header: "No",
            width: "20px",
            Cell: ({ index }) => <span>{index + 1}</span>,
        },
        {
            Header: "Level Image",
            body: "image",
            Cell: ({ row }) => (
                <div>
                    <img
                        src={baseURL + row?.levelImage ? baseURL + row?.levelImage :Male}
                        alt=""
                        draggable="false"
                        style={{
                            borderRadius: "5px",
                            boxShadow: "rgba(105, 103, 103, 0) 0px 5px 15px 0px",
                        }}
                        onClick={() => handleOpenImage(baseURL + row?.levelImage)}
                        height={80}
                        onError={(e) => e.target.src = Male}
                        width={120}
                    />
                </div>
            ),
        },
        {
            Header: "Icon",
            body: "image",
            Cell: ({ row }) => (
                <div>
                    <img
                        src={baseURL + row?.icon}
                        alt=""
                        draggable="false"
                        style={{
                            borderRadius: "5px",
                            boxShadow: "rgba(105, 103, 103, 0) 0px 5px 15px 0px",
                        }}
                        onClick={() => handleOpenImage(baseURL + row?.icon)}
                        height={80}
                        width={120}
                    />
                </div>
            ),
        },
        {
            Header: "Level",
            body: "level",
            Cell: ({ row }) => (
                <span>{row?.level}</span>
            ),
        },
        {
            Header: "Top Up Amount",
            body: "topUpAmount",
            Cell: ({ row }) => (
                <span>{row?.topUpAmount}</span>
            ),
        },

        {
            Header: "Edit",
            body: "edit",
            Cell: ({ row }) => (
                <div style={{ fontSize: "20px" }}
                    onClick={() => {
                        dispatch({
                            type: OPEN_DIALOGUE,
                            payload: { type: "vipLevelBanner", data: row },
                        });
                    }}>

                    <MdModeEdit />
                </div>
            ),
        },
    ];

    const handleFilterData = (data) => {
        console.log("dattttaaa", data)
    }

    return (
        <>
            <div className="mainCommission ">
                <div className="comission primeHeader">
                    <div className="comissionHeader ">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <Title name={`Vip Level`} />
                            </div>

                            <div className="col-6"></div>
                            <div className="col-6 ms-auto mt-3">
                                <Button
                                    newClass={`border text-white userBtn`}
                                    btnColor={`bg-primary`}
                                    btnName={`New`}
                                    btnIcon={`fas fa-plus fa-md`}
                                    onClick={() => {
                                        dispatch({
                                            type: OPEN_DIALOGUE,
                                            payload: { type: "vipLevelBanner" },
                                        });
                                    }}
                                    style={{
                                        borderRadius: "5px",
                                        marginLeft: "auto",
                                        width: "120px",
                                        float: "right",
                                    }}
                                />

                                {dialogue && dialogueType === "vipLevelBanner" && (
                                    <VipLevelDialogue />

                                )}
                            </div>
                        </div>
                    </div>
                    <div className="userMain">
                        <div className="tableMain mt-3 m-0">
                            <Table
                                data={data}
                                mapData={mapData}
                                PerPage={rowsPerPage}
                                Page={page}
                                type={"client"}
                            />
                        </div>
                        <div className="paginationFooter">
                            <Pagination
                                component="div"
                                count={vipLevel?.length}
                                serverPage={page}
                                type={"client"}
                                onPageChange={handleChangePage}
                                serverPerPage={rowsPerPage}
                                totalData={vipLevel?.length}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(null, {
    getBanner,
    deleteBanner,
    handleVIPSwitch,
})(VipLevel);
