import axios from "axios";
import { setToast } from "../../util/toast";
import * as ActionType from "./feedback.type";
import { apiInstanceFetch } from "../../util/api";

export const getUserFeedBack =
  (search, type, start, limit, startDate, endDate) => (dispatch) => {
    
    apiInstanceFetch
      .get(
        `admin/feedback/getAllFeedbackForUser?search=${search}&type=${type}&start=${start}&limit=${limit}
      &startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        
        dispatch({
          type: ActionType.GET_USER_FEEDBACK,
          payload: {data:res.data,total:res.total}
        });
      })
      .catch((error) => console.log("error", error.message));
  };
export const getHostFeedBack =
  (search, type, start, limit, startDate, endDate) => (dispatch) => {
    
    apiInstanceFetch
      .get(
        `admin/feedback/getAllHostFeedback?search=${search}&type=${type}&start=${start}
      &limit=${limit}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((res) => {
        
        dispatch({
          type: ActionType.GET_HOST_FEEDBACK,
          payload: {data:res.data,total:res.total}

        });
        console.log(res.data, "feedback");
      })
      .catch((error) => console.log("error", error.message));
  };

export const feedBackSolved = (data , id) => (dispatch) => {

  axios
    .put(`admin/feedback/${id}`, data)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({ type: ActionType.FEEDBACK_SOLVED, payload:{data : res.data.feedback ,id: id} });
        if (res.data.status && res.data.feedback.isSolved === true) {
          setToast("success", "Feedback Solve");
        }
        // setToast("success", "Feedback Solve");
      } else {
        setToast("error", res.data.message);

      }
    })
    .catch((error) => setToast("error", error.message));
};

export const deleteFeedBack = (feedBackId) => (dispatch) => {
  console.log(feedBackId);
  axios
    .delete(`admin/feedback/${feedBackId}`)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({ type: ActionType.FEEDBACK_DELETE, payload: feedBackId });
        setToast("success" , "Delete Successfully")
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
