import { useEffect, useState } from "react";
import Input from "../../../extra/Input";
import Title from "../../../extra/Title";
import SettingBox from "../SettingBox";
import Button from "../../../extra/Button";
import ToggleSwitch from "../../../extra/ToggleSwitch";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getSetting,
  updateMultiAppSetting,
  isAppActiveSwitch,
  getAppWiseSetting,
  allSwitchType,
  verifyName,
  verifyPackage,
  addMultiAppSetting,
  getMultiAppSetting,
} from "../../../store/setting/setting.action";
import { Tune } from "@material-ui/icons";
import MonetizationSetting from "./MonetizationSetting";
import GoogleAdd from "./GoogleAdd";
import { CLOSE_DIALOGUE } from "../../../store/dialogue/dialogue.type";

import { EMPTY_APP_WISE_SETTING } from "../../../store/setting/setting.type";
import { baseURL } from "../../../util/config";
import Multiselect from "multiselect-react-dropdown";
import { setToast } from "../../../util/toast";

const MultiAppSetting = (props) => {
  const { appWiseSetting, multiAppSetting } = useSelector((state) => state.setting);
  const hostDetail = useSelector((state) => state.setting.hostDetail);
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  console.log("appWiseSetting",  appWiseSetting)
  const [type, setType] = useState("App");

  // box 1
  const [logoType, setLogoType] = useState(1);
  const [videoType, setVideoType] = useState(1);
  const [logo, setLogo] = useState([]);
  const [logoPath, setLogoPath] = useState("");
  const [video, setVideo] = useState([]);
  const [videoPath, setVideoPath] = useState("");
  const [privacyPolicyLink, setPrivacyPolicyLink] = useState("");
  const [packageName, setPackageName] = useState("");
  const [appName, setAppName] = useState("");
  // const [privacyPolicyText, setPrivacyPolicyText] = useState("");
  const [appVersion, setAppVersion] = useState();
  const [termAndCondition, setTermAndCondition] = useState("");
  const [appRedirectUrl, setAppRedirectUrl] = useState("");
  const [ipApiBaseUrl, setIpApiBaseUrl] = useState("");
  const [ipApiEndPoint, setIpApiEndPoint] = useState("");

  const [agoraKey, setAgoraKey] = useState("");

  const [agoraCertificate, setAgoraCertificate] = useState("");
  // box 2
  const [googleInAppKey, setGoogleInAppKey] = useState("");
  const [googleInAppEmail, setGoogleInAppEmail] = useState("");
  const [upiPayKey, setUpiPayKey] = useState("");
  const [isAppActive, setAppIsAppActive] = useState(false);
  const [isFakeCall, setIsFakeCall] = useState(false);
  const [googleLogin, setGoogleLogin] = useState(false);
  const [quickLogin, setQuickLogin] = useState(false);
  const [razorPaySwitch, setRazorPaySwitch] = useState(false);
  const [stripePay, setStripePay] = useState(false);
  const [googlePay, setGooglePay] = useState(false);
  const [phonePe, setPhonePe] = useState(false);
  const [paytm, setPaytm] = useState(false);
  const [bhim, setBhim] = useState(false);
  const [googleInAppSwitch, setGoogleInAppSwitch] = useState(false);
  const [upiPaySwitch, setUpiPaySwitch] = useState(false);
  const [chargeForMatchMale, setChargeForMatchMale] = useState();
  const [durationOfFreeCall, setDurationOfFreeCall] = useState();
  const [chargeForMatchFemale, setChargeForMatchFemale] = useState();

  const [loginBonus, setLoginBonus] = useState();
  const [maxLoginBonus, setMaxLoginBonus] = useState();

  const [fakeCallDuration, setFakeCallDuration] = useState();
  const [fakeCallCount, setFakeCallCount] = useState();
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedValue1, setSelectedValue1] = useState([]);

  const [freeStoryView, setFreeStoryView] = useState();

  const [referralBonus, setReferralBonus] = useState();
  const [verifyPaymentTime, setVerifyPaymentTime] = useState();
  const [maxLiveView, setMaxLiveView] = useState();
  const [minLiveView, setMinLiveView] = useState();

  const [facebookAppID, setFacebookAppID] = useState("");
  const [facebookAppSecret, setFacebookAppSecret] = useState("");
  // box-5
  const [razorPayPublishableKey, setRazorPayPublishableKey] = useState("");
  const [razorPaySecretKy, setRazorPaySecretKy] = useState("");
  const [stripeSecretKey, setStripeSecretKey] = useState("");
  const [stripePublishableKey, setStripePublishableKey] = useState("");
  const [inidanPaymentGeteway, setInidanPaymentGeteway] = useState([]);
  const [otherPaymentGeteway, setOtherPaymentGeteway] = useState([]);
  const [monetizationBtn, setMonetizationBtn] = useState(false);
  const [showErrorBtn, setShowErrorBtn] = useState(false);

  const [appCreateObj, setAppCreateObj] = useState({});
  const [mongoId, setMongoId] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState({
    logo: "",
    logoPath: "",
    video: "",
    videoPath: "",
    chargeForMatchMale: "",
    chargeForMatchFemale: "",
    loginBonus: "",
    maxLoginBonus: "",
    termAndCondition: "",
    appRedirectUrl: "",
    ipApiBaseUrl: "",
    ipApiEndPoint: "",
    appName: "",
    packageName: "",
    privacyPolicyLink: "",
    durationOfFreeCall: "",
    referralBonus: "",
    verifyPaymentTime: "",
    // freeStoryView: "",
    stripeSecretKey: "",
    stripePublishableKey: "",
    razorPaySecretKy: "",
    razorPayPublishableKey: "",
    maxLiveView: "",
    minLiveView: "",
    fakeCallDuration: "",
    fakeCallCount: "",
    upiPayKey: "",
    googleInAppKey: "",
    googleInAppEmail: "",
    appVersion: "",
    inidanPaymentGeteway: "",
    otherPaymentGeteway: "",
  });
  console.log("appVersion", appVersion)
  useEffect(() => {
    if (dialogueData) {
      // dispatch(getAppWiseSetting(dialogueData?._id));
      setMongoId(dialogueData?._id);
    }
  }, [dialogueData]);
  console.log("upiPayKey", upiPayKey, stripePublishableKey)

  useEffect(() => {
    setLogo("");
    setVideo("");
    setVideoPath("");
    setAgoraKey("");
    setAgoraCertificate("");
    setPrivacyPolicyLink("");
    setPackageName("");
    setAppName("");
    setTermAndCondition("");
    setAppRedirectUrl("");
    setIpApiBaseUrl("");
    setIpApiEndPoint("");
    setAppIsAppActive("");
    setIsFakeCall("");
    setGoogleLogin("");
    setQuickLogin("");
    setStripePay("");
    setBhim("");
    setInidanPaymentGeteway("");
    setOtherPaymentGeteway("");
    setGooglePay("");
    setPhonePe("");
    setPaytm("");
    setRazorPaySwitch("");
    setUpiPaySwitch("");
    setGoogleInAppSwitch("");
    setRazorPayPublishableKey("");
    setRazorPaySecretKy("");
    setStripePublishableKey("");
    setStripeSecretKey("");
    setUpiPayKey("");
    setGoogleInAppKey("");
    setGoogleInAppEmail("");
    setFacebookAppID("");
    setFacebookAppSecret("");
  }, [dialogue]);
  console.log("dialogueData", dialogueData)
  useEffect(() => {
    if (dialogueData || hostDetail) {
      const data = dialogueData?.inidanPaymentGeteway?.map((data) => {
        return {
          name: data,
        };
      });
      const data1 = dialogueData?.otherPaymentGeteway?.map((data) => {
        return {
          name: data,
        };
      });
      // box 1
      setLogoType(dialogueData?.logoType ? dialogueData?.logoType : 1);
      setLogo(dialogueData?.logo);
      setLogoPath(
        dialogueData?.logoType == 0
          ? baseURL + dialogueData?.logo
          : dialogueData?.logo
      );
      setVideoType(hostDetail?.videoType ? hostDetail?.videoType : 1);
      setVideo(hostDetail?.video);
      setVideoPath(
        hostDetail?.videoType == 0 ? hostDetail?.video : hostDetail?.video
      );
      setAgoraKey(dialogueData?.agoraKey);
      setAgoraCertificate(dialogueData?.agoraCertificate);
      setPrivacyPolicyLink(dialogueData?.privacyPolicyLink);
      setPackageName(dialogueData?.packageName);
      setAppName(dialogueData?.appName);
      // box 2
      // setPrivacyPolicyText(dialogueData?.privacyPolicyText);
      setAppVersion(dialogueData?.appVersion);
      setTermAndCondition(dialogueData?.termAndCondition);
      setAppRedirectUrl(dialogueData?.appRedirectUrl);
      setIpApiBaseUrl(dialogueData?.ipApiBaseUrl);
      setIpApiEndPoint(dialogueData?.ipApiEndPoint);
      setAppIsAppActive(dialogueData?.isAppActive);
      setIsFakeCall(dialogueData?.isFakeCall);
      setGoogleLogin(dialogueData?.googleLogin);
      setQuickLogin(dialogueData?.quickLogin);
      setStripePay(dialogueData?.stripeSwitch);
      setBhim(dialogueData?.bhim);
      setGooglePay(dialogueData?.googlePay);
      setPhonePe(dialogueData?.phonePe);
      setPaytm(dialogueData?.paytm);
      setRazorPaySwitch(dialogueData?.razorpaySwitch);
      setUpiPaySwitch(dialogueData?.upiPaySwitch);
      setGoogleInAppSwitch(dialogueData?.googleInAppSwitch);

      setFacebookAppID(dialogueData?.facebookAppID);
      setFacebookAppSecret(dialogueData?.facebookAppSecret);

      // box 3
      setChargeForMatchMale(dialogueData?.chargeForMatchMale);
      setDurationOfFreeCall(dialogueData?.durationOfFreeCall);

      setChargeForMatchFemale(dialogueData?.chargeForMatchFemale);
      setLoginBonus(dialogueData?.loginBonus);
      setMaxLoginBonus(dialogueData?.maxLoginBonus);

      setReferralBonus(dialogueData?.referralBonus);
      setVerifyPaymentTime(dialogueData?.verifyPaymentTime);

      // box -4
      setRazorPayPublishableKey(dialogueData?.razorPayPublishableKey);
      setRazorPaySecretKy(dialogueData?.razorPaySecretKy);
      setStripePublishableKey(dialogueData?.stripePublishableKey);
      setStripeSecretKey(dialogueData?.stripeSecretKey);
      setUpiPayKey(dialogueData?.upiPayKey);
      setGoogleInAppKey(dialogueData?.googleInAppKey);
      setGoogleInAppEmail(dialogueData?.googleInAppEmail);

      // box-5
      // setFreeStoryView(dialogueData?.freeStoryView);

      // box-6
      setMaxLiveView(dialogueData?.maxLiveView);
      setMinLiveView(dialogueData?.minLiveView);

      // box-6
      setFakeCallDuration(dialogueData?.fakeCallDuration);
      setFakeCallCount(dialogueData?.fakeCallCount);

      setInidanPaymentGeteway(dialogueData?.inidanPaymentGeteway);
      setSelectedValue(data);

      setOtherPaymentGeteway(dialogueData?.otherPaymentGeteway);
      setSelectedValue1(data1);
    }
  }, [dialogueData, hostDetail]);

  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };

  //onselect function of selecting multiple values

  function onSelect(selectedList, selectedItem) {
    inidanPaymentGeteway.push(selectedItem.name);
  }


  useEffect(() => {
    dispatch(getMultiAppSetting());
  }, [dispatch]);

  //onRemove function for remove multiple values
  function onRemove(selectedList, removedItem) {
    setInidanPaymentGeteway(selectedList.map((data) => data.name));
  }

  // other payment gate way
  //onselect function of selecting multiple values

  function onSelect1(selectedList, selectedItem) {
    otherPaymentGeteway.push(selectedItem.name);
  }

  //onRemove function for remove multiple values
  function onRemove1(selectedList, removedItem) {
    setOtherPaymentGeteway(selectedList.map((data) => data.name));
  }

  const handleOneImage = (e) => {
    setError((prevErrors) => ({
      ...prevErrors,
      logo: "",
    }));
    setLogo(e.target.files[0]);
    setLogoPath(URL.createObjectURL(e.target.files[0]));
  };
  const handleOneVideo = (e) => {
    setError((prevErrors) => ({
      ...prevErrors,
      video: "",
    }));
    setVideo(e.target.files[0]);
    setVideoPath(URL.createObjectURL(e.target.files[0]));
  };

  useEffect(() => {
    if (type === "App") {
      setMonetizationBtn(true);
    }
  }, []);

  const isValidURL = (url) => {
    if (typeof url !== "string") {
      return false; // Make sure url is a string
    }

    const urlRegex = /^(ftp|http|https):\/[^ "]+$/;
    return urlRegex.test(url) || url?.startsWith("blob:");
  };

  const handleApiCall = async (data) => {
    if (data?.appName) {
      if (data?.appName?.length > 3) {
        dispatch(verifyName(data?.appName));
      }
    } else {
      if (data?.packageName?.length > 3) {
        dispatch(verifyPackage(data?.packageName));
      }
    }
  };

  const handleNext = (type) => {
    const loginBonusValid = isNumeric(loginBonus);
    const maxLoginBonusValid = isNumeric(maxLoginBonus);
    const chargeForMatchMaleValid = isNumeric(chargeForMatchMale);
    const chargeForMatchFemaleValid = isNumeric(chargeForMatchFemale);
    const durationOfFreeCallValid = isNumeric(durationOfFreeCall);
    const appVersionValid = isNumeric(appVersion);
    const referralBonusValid = isNumeric(referralBonus);
    const verifyPaymentTimeValid = isNumeric(verifyPaymentTime);
    const maxLiveViewValid = isNumeric(maxLiveView);
    const minLiveViewValid = isNumeric(minLiveView);
    const logoPathValidLink = isValidURL(logoPath);
    const videoPathValidLink = isValidURL(videoPath);
    const appRedirectLink = isValidURL(appRedirectUrl);
    const ipApiBaseUrlLink = isValidURL(ipApiBaseUrl);
    const privacyPolicyLinkValid = isValidURL(privacyPolicyLink);
    const termAndConditionValid = isValidURL(termAndCondition);

    if (
      (logoType == 1 ? !logoPath || !logoPathValidLink : !logo) ||
      !upiPayKey ||
      !stripePublishableKey ||
      !razorPaySecretKy ||
      !razorPayPublishableKey ||
      !stripeSecretKey ||
      !appName ||
      !packageName ||
      !termAndCondition ||
      !appRedirectUrl ||
      !ipApiEndPoint ||
      !appRedirectLink ||
      !ipApiBaseUrlLink ||
      !ipApiBaseUrl ||
      !termAndConditionValid
    ) {
      let error = {};

      if (!stripeSecretKey) {
        error.stripeSecretKey = "Required";
        document.getElementById("stripeSecretKey")?.focus();
      }
      if (!stripePublishableKey) {
        error.stripePublishableKey = "Required";
        document.getElementById("stripePublishableKey")?.focus();
      }
      if (!razorPaySecretKy) {
        error.razorPaySecretKy = "Required";
        document.getElementById("razorPaySecretKy")?.focus();
      }
      if (!razorPayPublishableKey) {
        error.razorPayPublishableKey = "Required";
        document.getElementById("razorPayPublishableKey")?.focus();
      }

      if (!termAndCondition) {
        error.termAndCondition = "Required";
        document.getElementById("termAndCondition")?.focus();
      }
      if (!termAndConditionValid) {
        error.termAndCondition = "Invalid Link !";
        document.getElementById("termAndConditionValid")?.focus();
      }
      if (!appRedirectUrl) {
        error.appRedirectUrl = "Required";
        document.getElementById("appRedirectUrl")?.focus();
      }
      if (!ipApiBaseUrlLink) {
        error.ipApiBaseUrl = "Required";
        document.getElementById("ipApiBaseUrl")?.focus();
      }
      if (!ipApiBaseUrl) {
        error.ipApiBaseUrl = "Required";
        document.getElementById("ipApiBaseUrl")?.focus();
      }
      if (!ipApiEndPoint) {
        error.ipApiEndPoint = "Required";
        document.getElementById("ipApiEndPoint")?.focus();
      }
      // if (!verifyPaymentTimeValid) {
      //   error.verifyPaymentTime = "Invalid !";
      //   document.getElementById("verifyPaymentTime")?.focus();
      // }
      if (!appRedirectLink) {
        error.appRedirectUrl = "Invalid Link !";
        document.getElementById("appRedirectUrl")?.focus();
      }

      if (!packageName) {
        error.packageName = "Required";
        document.getElementById("packageName")?.focus();
      }
      if (!appName) {
        error.appName = "Required";
        document.getElementById("appName")?.focus();
      }

      if (!upiPayKey) {
        error.upiPayKey = "Required";
        document.getElementById("upiPayKey")?.focus();
      }
      if (logoType == 1 ? !logoPath || !logoPathValidLink : !logo) {
        error.logo = "Invalid Image Link!";
        document.getElementById("logo")?.focus();
      }
      if (logoPath ? !logoPath : logo?.length == 0)
        error.logo = "Image is Required !";
      document.getElementById("logoPath")?.focus();
      return setError({ ...error });
    } else {
      // setMonetizationBtn(false);
      setShowErrorBtn(false);

      if (type === "submitBtn" && mongoId) {
        dispatch({ type: CLOSE_DIALOGUE });
        dispatch({ type: EMPTY_APP_WISE_SETTING });
        const formData = new FormData();
        if (logoType == 1) {
          formData.append("logo", logoPath);
        } else {
          formData.append("logo", logo);
        }

        formData.append("logoType", logoType);
        formData.append("stripeSecretKey", stripeSecretKey);
        formData.append("stripePublishableKey", stripePublishableKey);
        formData.append("razorPaySecretKy", razorPaySecretKy);
        formData.append("agoraKey", agoraKey);
        formData.append("razorPayPublishableKey", razorPayPublishableKey);
        formData.append("quickLogin", quickLogin);
        formData.append("googleLogin", googleLogin);
        formData.append("razorPaySwitch", razorPaySwitch);
        formData.append("stripePay", stripePay);
        formData.append("upiPayKey", upiPayKey);
        formData.append("bhim", bhim);
        formData.append("googlePay", googlePay);
        formData.append("phonePe", phonePe);
        formData.append("paytm", paytm);
        formData.append("upiPaySwitch", upiPaySwitch);
        formData.append("googleInAppSwitch", googleInAppSwitch);
        formData.append("appName", appName);
        formData.append("packageName", packageName);
        formData.append("agoraCertificate", agoraCertificate);
        formData.append("privacyPolicyLink", privacyPolicyLink);
        formData.append("appVersion", appVersion);
        formData.append("termAndCondition", termAndCondition);
        formData.append("appRedirectUrl", appRedirectUrl);
        formData.append("ipApiEndPoint", ipApiEndPoint);
        formData.append("ipApiBaseUrl", ipApiBaseUrl);
        formData.append("isAppActive", isAppActive);
        formData.append("isFakeCall", isFakeCall);
        formData.append("fakeCallDuration", fakeCallDuration);
        formData.append("fakeCallCount", fakeCallCount);
        formData.append("chargeForMatchMale", chargeForMatchMale);
        formData.append("chargeForMatchFemale", chargeForMatchFemale);
        formData.append("loginBonus", loginBonus);
        formData.append("maxLoginBonus", maxLoginBonus);
        formData.append("durationOfFreeCall", durationOfFreeCall);
        formData.append("referralBonus", referralBonus);
        formData.append("verifyPaymentTime", verifyPaymentTime);
        // formData.append("freeStoryView", freeStoryView);
        formData.append("minLiveView", minLiveView);
        formData.append("maxLiveView", maxLiveView);
        formData.append("googleInAppKey", googleInAppKey);
        formData.append("googleInAppEmail", googleInAppEmail);
        formData.append("inidanPaymentGeteway", inidanPaymentGeteway);
        formData.append("otherPaymentGeteway", otherPaymentGeteway);
        formData.append("facebookAppID", facebookAppID);
        formData.append("facebookAppSecret", facebookAppSecret);

        props.updateMultiAppSetting(formData, mongoId);
      }



      // else {
      //   setType("Monetize App");
      //   const appCreateObjData = {
      //     logo: logo,
      //     video: video,
      //     logoPath: logoPath,
      //     logoType: logoType,
      //     stripeSecretKey: stripeSecretKey,
      //     stripePublishableKey: stripePublishableKey,
      //     razorPaySecretKy: razorPaySecretKy,
      //     agoraKey: agoraKey,
      //     razorPayPublishableKey: razorPayPublishableKey,
      //     quickLogin: quickLogin,
      //     googleLogin: googleLogin,
      //     razorPaySwitch: razorPaySwitch,
      //     stripePay: stripePay,
      //     bhim: bhim,
      //     googlePay: googlePay,
      //     phonePe: phonePe,
      //     paytm: paytm,
      //     upiPaySwitch: upiPaySwitch,
      //     googleInAppSwitch: googleInAppSwitch,
      //     appName: appName,
      //     packageName: packageName,
      //     mongoId: mongoId,
      //     agoraCertificate: agoraCertificate,
      //     privacyPolicyLink: privacyPolicyLink,
      //     appVersion: appVersion ? appVersion : 1,
      //     termAndCondition: termAndCondition,
      //     appRedirectUrl: appRedirectUrl,
      //     ipApiBaseUrl: ipApiBaseUrl,
      //     ipApiEndPoint: ipApiEndPoint,
      //     isAppActive: isAppActive,
      //     isFakeCall: isFakeCall ? isFakeCall : true,
      //     fakeCallDuration: fakeCallDuration ? fakeCallDuration : 5,
      //     fakeCallCount: fakeCallCount ? fakeCallCount : 0,
      //     chargeForMatchMale: chargeForMatchMale ? chargeForMatchMale : 10,
      //     chargeForMatchFemale: chargeForMatchFemale
      //       ? chargeForMatchFemale
      //       : 10,
      //     loginBonus: loginBonus ? loginBonus : 30,
      //     maxLoginBonus: maxLoginBonus ? maxLoginBonus : 100,
      //     durationOfFreeCall: durationOfFreeCall ? durationOfFreeCall : 30,
      //     referralBonus: referralBonus ? referralBonus : 10,
      //     verifyPaymentTime: verifyPaymentTime ? verifyPaymentTime : 7,
      //     freeStoryView: freeStoryView ? freeStoryView : 5,
      //     minLiveView: minLiveView ? minLiveView : 5,
      //     maxLiveView: maxLiveView ? maxLiveView : 15,
      //     googleInAppKey: googleInAppKey,
      //     googleInAppEmail: googleInAppEmail,
      //     upiPayKey: upiPayKey,
      //     facebookAppID: facebookAppID,
      //     facebookAppSecret: facebookAppSecret,
      //   };

      //   setAppCreateObj(appCreateObjData);
      // }
    }
  };
  console.log("stripeSecretKey", stripeSecretKey, logoPath, appName, packageName, appVersion, privacyPolicyLink, termAndCondition, appRedirectUrl, ipApiBaseUrl, ipApiEndPoint, razorPaySecretKy)
  const handleSubmit = () => {
    const loginBonusValid = isNumeric(loginBonus);
    const maxLoginBonusValid = isNumeric(maxLoginBonus);
    const chargeForMatchMaleValid = isNumeric(chargeForMatchMale);
    const chargeForMatchFemaleValid = isNumeric(chargeForMatchFemale);
    const durationOfFreeCallValid = isNumeric(durationOfFreeCall);
    const appVersionValid = isNumeric(appVersion);
    const referralBonusValid = isNumeric(referralBonus);
    const verifyPaymentTimeValid = isNumeric(verifyPaymentTime);
    const maxLiveViewValid = isNumeric(maxLiveView);
    const minLiveViewValid = isNumeric(minLiveView);
    const logoPathValidLink = isValidURL(logoPath);
    const videoPathValidLink = isValidURL(videoPath);
    const appRedirectLink = isValidURL(appRedirectUrl);
    const ipApiBaseUrlLink = isValidURL(ipApiBaseUrl);
    const privacyPolicyLinkValid = isValidURL(privacyPolicyLink);
    const termAndConditionValid = isValidURL(termAndCondition);

    if (
      (logoType == 1 ? !logoPath || !logoPathValidLink : !logo) ||
      !upiPayKey ||
      !stripePublishableKey ||
      !razorPaySecretKy ||
      !razorPayPublishableKey ||
      !stripeSecretKey ||
      !appName ||
      !packageName ||
      !termAndCondition ||
      !appRedirectUrl ||
      !ipApiEndPoint ||
      !appRedirectLink ||
      !ipApiBaseUrlLink ||
      !ipApiBaseUrl ||
      !termAndConditionValid
    ) {
      let error = {};

      if (!stripeSecretKey) {
        error.stripeSecretKey = "Required";
        document.getElementById("stripeSecretKey")?.focus();
      }
      if (!stripePublishableKey) {
        error.stripePublishableKey = "Required";
        document.getElementById("stripePublishableKey")?.focus();
      }
      if (!razorPaySecretKy) {
        error.razorPaySecretKy = "Required";
        document.getElementById("razorPaySecretKy")?.focus();
      }
      if (!razorPayPublishableKey) {
        error.razorPayPublishableKey = "Required";
        document.getElementById("razorPayPublishableKey")?.focus();
      }

      if (!termAndCondition) {
        error.termAndCondition = "Required";
        document.getElementById("termAndCondition")?.focus();
      }
      if (!termAndConditionValid) {
        error.termAndCondition = "Invalid Link !";
        document.getElementById("termAndConditionValid")?.focus();
      }
      if (!appRedirectUrl) {
        error.appRedirectUrl = "Required";
        document.getElementById("appRedirectUrl")?.focus();
      }
      if (!ipApiBaseUrlLink) {
        error.ipApiBaseUrl = "Required";
        document.getElementById("ipApiBaseUrl")?.focus();
      }
      if (!ipApiBaseUrl) {
        error.ipApiBaseUrl = "Required";
        document.getElementById("ipApiBaseUrl")?.focus();
      }
      if (!ipApiEndPoint) {
        error.ipApiEndPoint = "Required";
        document.getElementById("ipApiEndPoint")?.focus();
      }
      // if (!verifyPaymentTimeValid) {
      //   error.verifyPaymentTime = "Invalid !";
      //   document.getElementById("verifyPaymentTime")?.focus();
      // }
      if (!appRedirectLink) {
        error.appRedirectUrl = "Invalid Link !";
        document.getElementById("appRedirectUrl")?.focus();
      }

      if (!packageName) {
        error.packageName = "Required";
        document.getElementById("packageName")?.focus();
      }
      if (!appName) {
        error.appName = "Required";
        document.getElementById("appName")?.focus();
      }

      if (!upiPayKey) {
        error.upiPayKey = "Required";
        document.getElementById("upiPayKey")?.focus();
      }
      if (logoType == 1 ? !logoPath || !logoPathValidLink : !logo) {
        error.logo = "Invalid Image Link!";
        document.getElementById("logo")?.focus();
      }
      if (logoPath ? !logoPath : logo?.length == 0)
        error.logo = "Image is Required !";
      document.getElementById("logoPath")?.focus();
      return setError({ ...error })
    }
    else {
      const formData = new FormData();
      if (logoType == 1) {
        formData.append("logo", logoPath);
      } else {
        formData.append("logo", logo);
      }
      // if (videoType == 1) {
      //   formData.append("adminVideo", videoPath);
      // } else {
      //   formData.append("adminVideo", video);
      // }
      formData.append("logoType", logoType);
      formData.append("videoType", videoType);
      formData.append("stripeSecretKey", stripeSecretKey);
      formData.append(
        "stripePublishableKey",
        stripePublishableKey
      );
      formData.append("razorPaySecretKy", razorPaySecretKy);
      formData.append("agoraKey", agoraKey);
      formData.append(
        "razorPayPublishableKey",
        razorPayPublishableKey
      );
      formData.append("quickLogin", quickLogin);
      formData.append("googleLogin", googleLogin);
      formData.append("razorPaySwitch", razorPaySwitch);
      formData.append("stripePay", stripePay);
      formData.append("bhim", bhim);
      formData.append("googlePay", googlePay);
      formData.append("phonePe", phonePe);
      formData.append("paytm", paytm);
      formData.append("upiPaySwitch", upiPaySwitch);
      formData.append("googleInAppSwitch", googleInAppSwitch);
      formData.append("appName", appName);
      formData.append("packageName", packageName);
      formData.append("agoraCertificate", agoraCertificate);
      formData.append("privacyPolicyLink", privacyPolicyLink);
      formData.append("appVersion", appVersion);
      formData.append("termAndCondition", termAndCondition);
      formData.append("appRedirectUrl", appRedirectUrl);
      formData.append("ipApiBaseUrl", ipApiBaseUrl);
      formData.append("ipApiEndPoint", ipApiEndPoint);
      formData.append("isAppActive", isAppActive);
      formData.append("isFakeCall", isFakeCall);
      formData.append("fakeCallDuration", fakeCallDuration);
      formData.append("fakeCallCount", fakeCallCount);
      formData.append("chargeForMatchMale", chargeForMatchMale);
      formData.append(
        "chargeForMatchFemale",
        chargeForMatchFemale
      );
      formData.append("loginBonus", loginBonus);
      formData.append("maxLoginBonus", maxLoginBonus);
      formData.append("durationOfFreeCall", durationOfFreeCall);
      formData.append("referralBonus", referralBonus);
      formData.append("verifyPaymentTime", verifyPaymentTime);
      // formData.append("freeStoryView", freeStoryView);
      formData.append("minLiveView", minLiveView);
      formData.append("maxLiveView", maxLiveView);
      formData.append("googleInAppKey", googleInAppKey);
      formData.append("googleInAppEmail", googleInAppEmail);


      // google

      formData.append("upiPayKey", upiPayKey);

      formData.append("facebookAppID", facebookAppID);
      formData.append("facebookAppSecret", facebookAppSecret);


      if (mongoId) {
        props.updateMultiAppSetting(formData, mongoId);
      } else {
        props.addMultiAppSetting(formData);
      }
      dispatch({ type: CLOSE_DIALOGUE });
    }

    // dispatch({ type: EMPTY_APP_WISE_SETTING });
  };
  const handleClick = () => {
    props.isAppActiveSwitch(appWiseSetting?._id);
  };
  const handleClickAllSwitch = (type) => {
    props.allSwitchType(multiAppSetting?.[0]?._id, type);
    // dispatch(getMultiAppSetting());
  };

  useEffect(() => {
    if (showErrorBtn === true) {
      const dataCheck = Object.values(error).every((value) => value === "");

      const dataAdd = dataCheck === true ? false : true;
      setMonetizationBtn(dataAdd);
    }
  }, [showErrorBtn, error]);

  $(document).ready(function () {
    $("#appSettingButton").click(function () {
      $("#monetizationSettingButton").css("box-shadow", "none");
      $(this).css("box-shadow", "0px 0px 10px rgba(0, 0, 0, 0.5)");
    });

    $("#monetizationSettingButton").click(function () {
      $("#appSettingButton").css("box-shadow", "none");
      $(this).css("box-shadow", "0px 0px 10px rgba(0, 0, 0, 0.5)");
    });
  });

  const handleBack = () => {
    dispatch({ type: CLOSE_DIALOGUE });
    dispatch({ type: EMPTY_APP_WISE_SETTING });
  };
  const option = [
    { name: "Google Play" },
    { name: "Stripe" },
    { name: "Razorpay" },
    { name: "UPI" },
  ];
  const option1 = [
    { name: "Google Play" },
    { name: "Stripe" },
    { name: "Razorpay" },
  ];

  return (
    <>
      <div className="mainSettingBar">
        <div className="aaaa">
          <div className="settingMain d-flex align-items-center justify-content-between">
            <div>
              <Button
                id={`appSettingButton`}
                newClass={` border  text-white ${type === "App" ? "AppShadow" : "MoniBoxShadow"
                  } `}
                btnColor={`btnthemePrime`}
                btnIcon={``}
                btnName={`App Setting`}
                onClick={() => setType("App")}
                style={{
                  borderRadius: "7px",
                  marginRight: "10px",
                  background: "#657a8f",
                }}
              />
              {/* <Button
                id={`monetizationSettingButton`}
                newClass={` border monetizationBtn text-white ${
                  type === "Monetize App" ? "AppShadow" : "MoniBoxShadow"
                } ${monetizationBtn ? "disabled" : "AppShadow"}`}
                btnColor={`btnthemePrime`}
                btnIcon={``}
                btnName={`monetization Setting`}
                disabled={monetizationBtn ? true : false}
                onClick={() => setType("Monetize App")}
                // disabled={type === "App" ? true : false}
                style={{
                  borderRadius: "7px",
                  opacity: `${monetizationBtn ? "0.4" : "1"}`,
                }}
              /> */}
            </div>
            <div className="settingMain d-flex align-items-center justify-content-between">
             


              <Button
                newClass={` border monetizationBtn text-white `}
                btnColor={`btnthemePrime`}
                btnIcon={`fas fa-arrow-left fa-md`}
                onClick={() => handleBack()}
                btnName={`Back`}
                style={{
                  borderRadius: "7px",
                }}
              />
            </div>

          </div>
          {mongoId && type == "App" && (
            <div className="settingMain pb-sm-0" style={{ width: "100%" }}>
              <div className="row d-flex align-items-start">
                <div className="col-xl-6 col-md-9 col-sm-8 m-auto  order-sm-1 order-md-0  mb-3">
                  <div className="loginType">
                    <table className="w-100">
                      <tbody>
                        <tr>
                          <td
                            className="fw-bolder text-dark bgSetting"
                            style={{
                              borderRight: "1px solid",
                              fontSize: "17px",
                            }}
                          >
                            Login Types
                          </td>
                          <td className="bgSetting">
                            <span className="fw-bold">Google Active</span>
                            <ToggleSwitch
                              onClick={() => {
                                handleClickAllSwitch("google");
                              }}
                              value={appWiseSetting?.googleLogin}
                            />
                          </td>
                          <td className="bgSetting text-end">
                            <span className="fw-bold">Quick Active</span>
                            <ToggleSwitch
                              onClick={() => {
                                handleClickAllSwitch("quick");
                              }}
                              value={appWiseSetting?.quickLogin}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {type === "App" && (
                  <div className="col-xl-6 col-md-3 col-sm-4 d-flex justify-content-end align-items-center order-sm-0 order-md-1 mb-sm-3">
                    <div className="d-flex align-items-center mt-3">
                      <Button
                        btnName={`Submit`}
                        // btnIcon={`fa-solid fa-circle-arrow-right ms-2 `}
                        btnColor={`btnBlackPrime`}
                        style={{
                          width: "140px",
                          borderRadius: "6px",
                          display: "flex",
                          borderRadius: "6px",
                          flexDirection: "row-reverse",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => handleNext("submitBtn")}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {type === "App" ? (
          <div className="settingBar ">
            <div className="settingMain appBox">
              <div className="row">
                <div className="col-xl-12 col-12">
                  <SettingBox title={`App Setting`}>
                    <div className="col-md-6 d-flex  mt-4">
                      <label className="text-gray">Profile Type :</label>
                      <div class="col-3 d-flex align-items-center">
                        <Input
                          id={`logo`}
                          type={`radio`}
                          value={0}
                          name={`logoType`}
                          newClass={`me-2 mb-0`}
                          onClick={(e) => {
                            setLogoType(e.target.value);
                          }}
                          checked={logoType == 0 ? true : false}
                        />
                        <label class="form-check-label" for="image">
                          Image
                        </label>
                      </div>
                      <div className=" col-3 d-flex align-items-center">
                        <Input
                          type={`radio`}
                          id={`linkLogo`}
                          value={1}
                          name={`logoType`}
                          newClass={`me-2 mb-0`}
                          onClick={(e) => {
                            setLogoType(e.target.value);
                          }}
                          checked={logoType == 1 ? true : false}
                        />
                        <label class="form-check-label" for="linkImage">
                          Link
                        </label>
                      </div>
                    </div>
                    <div className="row mt-4 align-items-center mb-4 pe-0">
                      <div
                        className={logoType == 1 ? "col-md-12 pe-0 " : "d-none"}
                      >
                        <div className="form-group">
                          <label className="mb-2 text-gray">Image Link</label>
                          <Input
                            type={`text`}
                            newClass={``}
                            id={`logo`}
                            // className="form-control"
                            placeholder={`Image link`}
                            value={logoPath}
                            onChange={(e) => {
                              setLogoPath(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  logo: "Image is Required!",
                                });
                              } else {
                                return setError({
                                  ...error,
                                  logo: "",
                                });
                              }
                            }}
                          />
                          {error.logo && (
                            <div className="ml-2 mt-1">
                              {error.logo && (
                                <div className="pl-1 text__left">
                                  <span className="errorMessage">
                                    {error.logo}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={logoType == 0 ? "col-md-6 pe-0" : "d-none"}
                      >
                        <span>Image</span>
                        <div className="mt-3 image-input">
                          <Input
                            type={`file`}
                            accept={`image/*`}
                            onChange={handleOneImage}
                            style={{ width: "100%" }}
                            newClass={`col-md-12 d-flex align-self-end`}
                          />
                          {error.logo && (
                            <div className="ml-2 mt-1">
                              {error.logo && (
                                <div className="pl-1 text__left">
                                  <span className="text-danger">
                                    {error.logo}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={
                          logoType == 0 ? "col-md-6 d-flex ps-5" : "d-none"
                        }
                      >
                        {logoPath && (
                          <>
                            <img
                              height="80px"
                              width="80px"
                              alt="app"
                              src={logoPath}
                              className="cursor"
                              style={{
                                boxShadow:
                                  "0 5px 15px 0 rgb(105 103 103 / 00%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                marginTop: 10,
                                height: "80px",
                                width: "80px",
                                float: "left",
                                objectFit: "cover",
                                marginRight: 15,
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>

                    <Input
                      type={`text`}
                      label={`App Name `}
                      value={appName}
                      id={`appName`}
                      errorMessage={error.appName}
                      newClass={`col-lg-6 col-sm-12 `}
                      placeholder={``}
                      onChange={(e) => {
                        handleApiCall({ appName: e.target.value });
                        setAppName(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            appName: "required",
                          });
                        } else {
                          return setError({
                            ...error,
                            appName: "",
                          });
                        }
                      }}
                    />
                    <Input
                      type={`text`}
                      label={`Package Name `}
                      value={packageName}
                      id={`packageName`}
                      errorMessage={error.packageName}
                      newClass={`col-lg-6 col-sm-12 `}
                      placeholder={``}
                      onChange={(e) => {
                        handleApiCall({ packageName: e.target.value });
                        setPackageName(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            packageName: "required",
                          });
                        } else {
                          return setError({
                            ...error,
                            packageName: "",
                          });
                        }
                      }}
                    />
                    <Input
                      type={`number`}
                      label={`App Version`}
                      value={appVersion}
                      id={`appVersion`}
                      newClass={`col-lg-6 col-sm-12`}
                      errorMessage={error.appVersion}
                      onChange={(e) => {
                        setAppVersion(e.target.value);
                      }}
                    />
                    <Input
                      type={`text`}
                      id={`privacyPolicyLink`}
                      label={`Privacy Policy Link`}
                      value={privacyPolicyLink}
                      newClass={`col-lg-6 col-sm-12`}
                      errorMessage={error.privacyPolicyLink}
                      placeholder={`Enter You Privacy Policy Link....`}
                      onChange={(e) => {
                        setPrivacyPolicyLink(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            privacyPolicyLink: "required",
                          });
                        } else {
                          return setError({
                            ...error,
                            privacyPolicyLink: "",
                          });
                        }
                      }}
                    />
                    <Input
                      type={`text`}
                      label={`Term And Condition Link`}
                      value={termAndCondition}
                      id={`termAndCondition`}
                      newClass={`col-md-6 col-12`}
                      errorMessage={error.termAndCondition}
                      placeholder={`Enter You Term And Condition Link....`}
                      onChange={(e) => {
                        setTermAndCondition(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            termAndCondition: "required",
                          });
                        } else {
                          return setError({
                            ...error,
                            termAndCondition: "",
                          });
                        }
                      }}
                    />
                    <Input
                      type={`text`}
                      label={`App Redirect URL`}
                      value={appRedirectUrl}
                      id={`appRedirectUrl`}
                      newClass={`col-md-6 col-12`}
                      errorMessage={error.appRedirectUrl}
                      placeholder={`Enter You App Redirect Reload URL....`}
                      onChange={(e) => {
                        setAppRedirectUrl(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            appRedirectUrl: "required",
                          });
                        } else {
                          return setError({
                            ...error,
                            appRedirectUrl: "",
                          });
                        }
                      }}
                    />
                    <Input
                      type={`text`}
                      label={`Ip Api Base Url`}
                      value={ipApiBaseUrl}
                      id={`ipApiBaseUrl`}
                      newClass={`col-md-6 col-12`}
                      errorMessage={error.ipApiBaseUrl}
                      placeholder={`Enter You Ip Api Base Url....`}
                      onChange={(e) => {
                        setIpApiBaseUrl(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            ipApiBaseUrl: "required",
                          });
                        } else {
                          return setError({
                            ...error,
                            ipApiBaseUrl: "",
                          });
                        }
                      }}
                    />
                    <Input
                      type={`text`}
                      label={`Ip Api End Point`}
                      value={ipApiEndPoint}
                      id={`ipApiEndPoint`}
                      newClass={`col-md-6 col-12`}
                      errorMessage={error.ipApiEndPoint}
                      placeholder={`Enter You Ip Api End Point....`}
                      onChange={(e) => {
                        setIpApiEndPoint(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            ipApiEndPoint: "required",
                          });
                        } else {
                          return setError({
                            ...error,
                            ipApiEndPoint: "",
                          });
                        }
                      }}
                    />

                    <div className="mainTitle mb-3">Functionality Setting</div>
                    <div className="mb-xl-4 mb-md-2 switch px-2 d-flex">
                      <div className="switchTitleColor text-center me-5">
                        <span className="fw-bold fs-6 col-2">Home </span>
                        <ToggleSwitch
                          onClick={() => {
                            handleClickAllSwitch("home ");
                          }}
                          value={appWiseSetting?.home}
                        />
                      </div>
                      <div className="switchTitleColor text-center me-5">
                        <span className="fw-bold fs-6 col-2">
                          Random match{" "}
                        </span>
                        <ToggleSwitch
                          onClick={() => {
                            handleClickAllSwitch("randomMatch");
                          }}
                          value={appWiseSetting?.randomMatch}
                        />
                      </div>
                      <div className="switchTitleColor text-center me-5">
                        <span className="fw-bold fs-6 col-2">Story </span>
                        <ToggleSwitch
                          onClick={() => {
                            handleClickAllSwitch("story");
                          }}
                          value={appWiseSetting?.story}
                        />
                      </div>
                      <div className="switchTitleColor text-center me-5">
                        <span className="fw-bold fs-6 col-2">isUpdate</span>
                        <ToggleSwitch
                          onClick={() => {
                            handleClickAllSwitch("isUpdate");
                          }}
                          value={appWiseSetting?.isUpdate}
                        />
                      </div>
                      <div className="switchTitleColor text-center me-5">
                        <span className="fw-bold fs-6 col-2">isRedirect</span>
                        <ToggleSwitch
                          onClick={() => {
                            handleClickAllSwitch("isRedirect");
                          }}
                          value={appWiseSetting?.isRedirect}
                        />
                      </div>
                    </div>
                    <div className="mainTitle mb-3">App Login Setting</div>
                    <Input
                      type={`number`}
                      label={`Login Bonus`}
                      value={loginBonus}
                      errorMessage={error.loginBonus}
                      newClass={`col-lg-6 col-md-6 col-sm-12`}
                      placeholder={``}
                      onChange={(e) => {

                        setLoginBonus(e.target.value);
                      }}
                    />
                    <Input
                      type={`number`}
                      label={`Max Login Bonus`}
                      value={maxLoginBonus}
                      errorMessage={error.maxLoginBonus}
                      newClass={`col-lg-6 col-md-6 col-sm-12`}
                      placeholder={``}
                      onChange={(e) => {
                        setMaxLoginBonus(e.target.value);
                      }}
                    />
                    <Input
                      type={`number`}
                      label={`Referral Bonus`}
                      value={referralBonus}
                      errorMessage={error.referralBonus}
                      newClass={`col-lg-6 col-md-6 col-sm-12`}
                      placeholder={``}
                      onChange={(e) => {
                        setReferralBonus(e.target.value);
                      }}
                    />
                    <Input
                      type={`number`}
                      label={`Verify Payment Time`}
                      value={verifyPaymentTime}
                      id={`verifyPaymentTime`}
                      errorMessage={error.verifyPaymentTime}
                      newClass={`col-lg-6 col-md-6 col-sm-12`}
                      placeholder={``}
                      onChange={(e) => {
                        setVerifyPaymentTime(e.target.value);
                      }}
                    />
                    <div className="mainTitle ">Payment Setting</div>
                    {dialogueData && (
                      <>
                        <div
                          className="row mb-4 mt-1"
                          style={{ padding: "13px" }}
                        >
                          <div className="col-md-6 col-12">
                            <div className="prime-input">
                              <label>Indian Payment Gateway</label>
                            </div>
                            <Multiselect
                              options={option} // Options to display in the dropdown
                              selectedValues={selectedValue} // Preselected value to persist in dropdown
                              onSelect={onSelect} // Function will trigger on select event
                              onRemove={onRemove} // Function will trigger on remove event
                              displayValue="name" // Property name to display in the dropdown options
                            />
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="prime-input">
                              <label>Other Country Payment Gateway</label>
                            </div>
                            <Multiselect
                              options={option1} // Options to display in the dropdown
                              selectedValues={selectedValue1} // Preselected value to persist in dropdown
                              onSelect={onSelect1} // Function will trigger on select event
                              onRemove={onRemove1} // Function will trigger on remove event
                              displayValue="name" // Property name to display in the dropdown options
                            />
                          </div>
                        </div>
                        <h5> UPI Pay Enable/Disable Setting </h5>
                        <div
                          className=" mt-4 mb-xl-4 mb-md-2 switch px-2 d-flex justify-content-between"
                          style={{
                            opacity: upiPaySwitch === false ? "0.4" : "1",
                          }}
                        >
                          <div className="switchTitleColor text-center">
                            <span className="fw-bold fs-6 col-2">
                              GooglePay{" "}
                            </span>
                            <ToggleSwitch
                              onClick={() => {
                                handleClickAllSwitch("googlePay");
                              }}
                              value={appWiseSetting?.googlePay}
                            />
                          </div>
                          <div className="switchTitleColor text-center">
                            <span className="fw-bold fs-6 col-2">PhonePe </span>
                            <ToggleSwitch
                              onClick={() => {
                                handleClickAllSwitch("phonePe");
                              }}
                              value={appWiseSetting?.phonePe}
                            />
                          </div>
                          <div className="switchTitleColor text-center">
                            <span className="fw-bold fs-6 col-2">Paytm </span>
                            <ToggleSwitch
                              onClick={() => {
                                handleClickAllSwitch("paytm");
                              }}
                              value={appWiseSetting?.paytm}
                            />
                          </div>
                          <div className="switchTitleColor text-center ">
                            <span className="fw-bold fs-6 col-2">BhimPay</span>
                            <ToggleSwitch
                              onClick={() => {
                                handleClickAllSwitch("bhim");
                              }}
                              value={appWiseSetting?.bhim}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <Input
                      type={`text`}
                      label={`Razor SecretKey `}
                      value={razorPaySecretKy}
                      id={`razorPaySecretKy`}
                      errorMessage={error.razorPaySecretKy}
                      newClass={`col-lg-6 col-sm-12 mt-3`}
                      placeholder={``}
                      onChange={(e) => {
                        setRazorPaySecretKy(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            razorPaySecretKy: "required",
                          });
                        } else {
                          return setError({
                            ...error,
                            razorPaySecretKy: "",
                          });
                        }
                      }}
                    />
                    <Input
                      type={`text`}
                      label={`Razor PublishableKey`}
                      value={razorPayPublishableKey}
                      id={`razorPayPublishableKey`}
                      errorMessage={error.razorPayPublishableKey}
                      newClass={`col-lg-6 col-sm-12 mt-3`}
                      placeholder={``}
                      onChange={(e) => {
                        setRazorPayPublishableKey(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            razorPayPublishableKey: "required",
                          });
                        } else {
                          return setError({
                            ...error,
                            razorPayPublishableKey: "",
                          });
                        }
                      }}
                    />
                    <Input
                      type={`text`}
                      label={`Stripe SecretKey `}
                      value={stripeSecretKey}
                      id={`stripeSecretKey`}
                      errorMessage={error.stripeSecretKey}
                      newClass={`col-lg-6 col-sm-12`}
                      placeholder={``}
                      onChange={(e) => {
                        setStripeSecretKey(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            stripeSecretKey: "required",
                          });
                        } else
                          return setError({
                            ...error,
                            stripeSecretKey: "",
                          });
                      }}
                    />
                    <Input
                      type={`text`}
                      label={`Stripe PublishableKey `}
                      id={`stripePublishableKey`}
                      value={stripePublishableKey}
                      errorMessage={error.stripePublishableKey}
                      newClass={`col-lg-6 col-sm-12`}
                      placeholder={``}
                      onChange={(e) => {
                        setStripePublishableKey(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            stripePublishableKey: "required",
                          });
                        } else
                          return setError({
                            ...error,
                            stripePublishableKey: "",
                          });
                      }}
                    />
                    <Input
                      type={`text`}
                      label={`UPI Key`}
                      value={upiPayKey}
                      id={`upiPayKey`}
                      errorMessage={error.upiPayKey}
                      newClass={`col-lg-12 col-sm-12`}
                      placeholder={``}
                      onChange={(e) => {
                        console.log("eeeeeee", e)
                        setUpiPayKey(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...error,
                            upiPayKey: "required",
                          });
                        } else
                          return setError({
                            ...error,
                            upiPayKey: "",
                          });
                      }}
                    />
                    <div className="mainTitle ">Coin Setting</div>
                    <Input
                      type={`number`}
                      label={`Charge for Male`}
                      value={chargeForMatchMale}
                      errorMessage={error.chargeForMatchMale}
                      newClass={`col-lg-6 col-md-6 col-sm-12 mt-3`}
                      placeholder={``}
                      onChange={(e) => {
                        setChargeForMatchMale(e.target.value);
                      }}
                    />
                    <Input
                      type={`number`}
                      label={`Charge for Female`}
                      value={chargeForMatchFemale}
                      errorMessage={error.chargeForMatchFemale}
                      newClass={`col-lg-6 col-md-6 col-sm-12 mt-3`}
                      placeholder={``}
                      onChange={(e) => {
                        setChargeForMatchFemale(e.target.value);
                      }}
                    />
                    <Input
                      type={`number`}
                      label={`Duration Of FreeCall`}
                      value={durationOfFreeCall}
                      errorMessage={error.durationOfFreeCall}
                      newClass={`col-lg-12 col-md-12 col-sm-12`}
                      placeholder={``}
                      onChange={(e) => {
                        setDurationOfFreeCall(e.target.value);
                      }}
                    />
                    <div className="mainTitle ">Live View Setting</div>
                    <Input
                      type={`number`}
                      label={`Min Live View (User)`}
                      value={minLiveView}
                      errorMessage={error.minLiveView}
                      newClass={`col-lg-6 col-md-6 col-sm-12 mt-3`}
                      placeholder={``}
                      onChange={(e) => {
                        setMinLiveView(e.target.value);
                      }}
                    />
                    <Input
                      type={`number`}
                      label={`Max Live View (User)`}
                      value={maxLiveView}
                      errorMessage={error.maxLiveView}
                      newClass={`col-lg-6 col-md-6 col-sm-12 mt-3`}
                      placeholder={``}
                      onChange={(e) => {
                        setMaxLiveView(e.target.value);
                      }}
                    />
                    <div className="d-flex justify-content-between">
                      <div className="mainTitle ">Fake Call Setting</div>
                      {dialogueData && (
                        <div className="switchTitleColor text-center">
                          <span className="fw-bold fs-6 col-2">isFakeCall</span>
                          <ToggleSwitch
                            onClick={() => {
                              handleClickAllSwitch("fakeCall");
                            }}
                            value={multiAppSetting?.[0]?.isFakeCall}
                          />
                        </div>
                      )}
                    </div>
                    <Input
                      type={`number`}
                      label={`Call Duration`}
                      value={fakeCallDuration}
                      errorMessage={error.fakeCallDuration}
                      newClass={`col-lg-6 col-sm-12 mt-1`}
                      style={{ marginTop: "4px" }}
                      placeholder={``}
                      onChange={(e) => {
                        setFakeCallDuration(e.target.value);
                      }}
                    />
                    <Input
                      type={`number`}
                      label={`Call Count`}
                      value={fakeCallCount}
                      errorMessage={error.fakeCallCount}
                      newClass={`col-lg-6 col-sm-12 mt-1`}
                      style={{ marginTop: "4px" }}
                      placeholder={``}
                      onChange={(e) => {
                        setFakeCallCount(e.target.value);
                      }}
                    />
                    <div className="d-flex align-items-center justify-content-end ">

                    {
                      !mongoId && (
                        <Button
                          btnName={`Submit`}
                          // btnIcon={`fa-solid fa-circle-arrow-right ms-2 `}
                          btnColor={`btnBlackPrime`}
                          style={{
                            width: "140px",
                            borderRadius: "6px",
                            display: "flex",
                            borderRadius: "6px",
                            flexDirection: "row-reverse",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={() => handleSubmit("submitBtn")}
                        />
                      )
                    }
                    </div>
                    {/* <div className="mainTitle ">Facebook App Setting</div>
                    <Input
                      type={`text`}
                      label={`Facebook App ID`}
                      value={facebookAppID}
                      newClass={`col-lg-6 col-sm-12 `}
                      placeholder={``}
                      onChange={(e) => {
                        setFacebookAppID(e.target.value);
                      }}
                    />
                    <Input
                      type={`text`}
                      label={`Facebook App Secret`}
                      value={facebookAppSecret}
                      newClass={`col-lg-6 col-sm-12 `}
                      placeholder={``}
                      onChange={(e) => {
                        setFacebookAppSecret(e.target.value);
                      }}
                    />
                    <div className="mainTitle ">Story Setting</div>
                    <Input
                      type={`number`}
                      label={`Free Story View`}
                      value={freeStoryView}
                      errorMessage={error.freeStoryView}
                      newClass={`col-lg-12col-sm-12 mt-2`}
                      placeholder={``}
                      onChange={(e) => {
                        setFreeStoryView(e.target.value);
                      }}
                    /> */}
                  </SettingBox>
                </div>

                {/* <div className="d-flex align-items-center mt-3 justify-content-end">
                  <Button
                    btnName={`Next to Monetization Settings....`}
                    btnIcon={`fa-solid fa-circle-arrow-right ms-2 `}
                    btnColor={`btnBlackPrime`}
                    style={{
                      width: "272px",
                      borderRadius: "6px",
                      display: "flex",

                      borderRadius: "6px",
                      flexDirection: "row-reverse",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => handleNext("nextBtn")}
                  />
                </div> */}
              </div>
            </div>
          </div>
        ) : (
          <GoogleAdd appCreateObj={appCreateObj} />
        )}
        { }
      </div>
    </>
  );
};

export default connect(null, {
  getSetting,
  updateMultiAppSetting,
  isAppActiveSwitch,
  addMultiAppSetting,
  allSwitchType,
  getAppWiseSetting,
})(MultiAppSetting);
