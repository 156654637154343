import React, { useEffect, useState } from "react";
import { CLOSE_DIALOGUE } from "../../store/dialogue/dialogue.type";
import Input from "../../extra/Input";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  addAgencyCommission,
  updateAgencyCommission,
} from "../../store/agency/agency.action";
import Button from "../../extra/Button";

const AgencyCommissionDialog = (props) => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();
  console.log("dialogueData::::::", dialogueData);

  const [mongoId, setMongoId] = useState();
  const [agencyId, setAgencyId] = useState();
  const [upperCoin, setUpperCoin] = useState(0);
  const [percentage, setPercentage] = useState();
  const [error, setError] = useState({
    upperCoin: "",
    percentage: "",
  });

  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData?.data?._id);
      setAgencyId(dialogueData?.agencyId);
      setUpperCoin(dialogueData?.data?.upperCoin);
      setPercentage(dialogueData?.data?.percentage);
    }
  }, [dialogueData]);

  const handleSubmit = (e) => {
    if (
      !percentage ||
      !upperCoin ||
      percentage < 0 ||
      upperCoin < 0 ||
      percentage > 99
    ) {
      const error = {};
      if (!percentage) error.percentage = "Percentage is required!";
      if (percentage < 0) error.percentage = "Invalid Percentage!";
      if (percentage > 99) error.percentage = "Invalid Percentage!";
      if (!upperCoin) error.upperCoin = "Upper coin is required!";
      if (upperCoin < 0) error.upperCoin = "Invalid UpperCoin!";
      return setError({ ...error });
    } else {
      const data = {
        percentage: percentage,
        upperCoin: upperCoin,
        commissionId: mongoId,
      };

      if (mongoId) {
        props.updateAgencyCommission(data, agencyId);
      } else {
        props.addAgencyCommission(data, dialogueData);
      }
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };

  return (
    <>
      <div className="mainDialogue fade-in">
        <div
          className="Dialogue"
          style={{ overflow: "auto", maxHeight: "100vh" }}
        >
          <div className="dialogueHeader">
            <div className="headerTitle fw-bold">Commisssion Dialog</div>
            <div
              className="closeBtn boxCenter"
              onClick={() => {
                dispatch({ type: CLOSE_DIALOGUE });
              }}
            >
              <i className="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="dialogueMain">
            <div className="row">
              <div className="col-6">
                <Input
                  label={`Upper Coin`}
                  id={`upperCoin`}
                  type={`number`}
                  value={upperCoin}
                  placeholder={`Enter Uppercoin`}
                  onFocus={(e) => {
                    e?.target?.value == 0 ? setUpperCoin("") : "";
                  }}
                  errorMessage={error.upperCoin && error.upperCoin}
                  onChange={(e) => {
                    setUpperCoin(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        upperCoin: `UpperCoin Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        upperCoin: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-6">
                <Input
                  label={`Percentage %`}
                  id={`percentage`}
                  type={`number`}
                  placeholder={`Enter Percentage`}
                  value={percentage}
                  errorMessage={error.percentage && error.percentage}
                  onChange={(e) => {
                    setPercentage(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        percentage: `Percentage Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        percentage: "",
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="dialogueFooter">
              <div className="dialogueBtn">
                <Button
                  btnName={`Submit`}
                  btnColor={`btnBlackPrime`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  newClass={`me-2`}
                  onClick={handleSubmit}
                />
                <Button
                  btnName={`Close`}
                  btnColor={`bg-danger text-white`}
                  style={{ borderRadius: "5px", width: "80px" }}
                  onClick={() => {
                    dispatch({ type: CLOSE_DIALOGUE });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, {
  updateAgencyCommission,
  addAgencyCommission,
})(AgencyCommissionDialog);
