import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import {
  getCategory,
  deleteCategory,
  categoryActive,
} from "../../store/giftCategory/action";

//routing
// import { Link, useHistory } from "react-router-dom";
// type

//sweet alert
import { alert, warning } from "../../util/Alert";

//image
// import noImage from "../../assets/images/noImage.png";
import { baseURL } from "../../util/config";

import IOSSwitch from "@material-ui/core/Switch";
import Title from "../../extra/Title";
import Button from "../../extra/Button";
import { OPEN_DIALOGUE } from "../../store/dialogue/dialogue.type";
import CategoryDialog from "./CategoryDialog";
import { useNavigate } from "react-router-dom";
import Male from "../../../assets/images/male.jpg";

const Category = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [active, setActive] = useState();

  useEffect(() => {
    dispatch(getCategory()); // eslint-disable-next-lin
  }, []);

  const { category } = useSelector((state) => state.category);
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  useEffect(() => {
    setData(category);
  }, [category]);

  const handleSearch = (e) => {
    const value = e.target.value.trim().toUpperCase();
    if (value) {
      const data = category.filter((data) => {
        return (
          data?.name?.toUpperCase()?.indexOf(value) > -1 ||
          data?.giftCount?.toString()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      return setData(category);
    }
  };

  //   const handleOpen = () => {
  //     dispatch({ type: OPEN_CATEGORY_DIALOG });
  //   };

  const handleDelete = (categoryId) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
          props.deleteCategory(categoryId);
          alert("Deleted!", `Category has been deleted!`, "success");
        }
      })
      .catch((err) => console.log(err));
  };

  const openGifts = (data) => {
    console.log(
      "dataxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx👩👩👩👩👩👩👩👩👩 :>> ",
      data
    );
    localStorage.removeItem("GiftClick");
    localStorage.setItem("Category", JSON.stringify(data));
    navigate("/admin/giftCategory/gifts", {
      state: data,
    });
  };

  const categoryHandleActive = (data) => {
    props.categoryActive(data, data?.isActive === true ? false : true);
  };

  return (
    <>
      <div className="mainUserTable">
        <div className="userTable">
          <div className="userHeader primeHeader">
            <div className="row">
              <Title name={`Gift Category`} />
            </div>
          </div>
          <div className="primeHeader mt-4 row">
            {/* <i className="fas fa-plus fa-md"></i> */}
            <div className="col-md-6">
              <Button
                newClass={`bgsuccess text-white border`}
                btnIcon={`fas fa-plus fa-md`}
                btnName={`New Category`}
                btnColor={`btnthemePrime`}
                onClick={() => {
                  dispatch({
                    type: OPEN_DIALOGUE,
                    payload: { type: "Category" },
                  });
                }}
                // navigate("/admin/addAgency");

                // onClick={handleChangeImage}
                style={{ borderRadius: "7px" }}
              />
              {dialogue && dialogueType === "Category" && <CategoryDialog />}
            </div>
            <div class="row mt-4">
              {data?.length > 0 ? (
                data?.map((data, index) => {
                  return (
                    <div class="col-12 col-sm-6 col-lg-4" key={index}>
                      <div
                        className={`card cursor-pointer categoryBox`}
                        style={{
                          opacity: data?.isActive === false ? "0.4" : "1",
                          borderRadius: "10px",
                        }}
                      >
                        <div className="blockSwich p-3 align-items-center d-flex justify-content-end">
                          <span className="fw-bold">
                            {data?.isActive === false ? "Deactivate" : "Active"}
                          </span>
                          <IOSSwitch
                            checked={data?.isActive}
                            color="primary"
                            onClick={() => categoryHandleActive(data)}
                          />
                        </div>
                        <div class="card-body text-center">
                          <img
                            src={baseURL + data?.image ? baseURL + data?.image : Male}
                            className="categoryImage"
                            alt="Gift"
                            draggable="false"
                            onClick={
                              data?.isActive === false
                                ? false
                                : () => openGifts(data)
                            }
                            onError={(e) => e.target.src = Male}
                          />

                          <p className="h4 mb-0 my-2 font-weight-bold text-dark">
                            {data?.name}
                          </p>
                          <p className="text-dark font-weight-bold fs-6 my-2">
                            {data?.giftCount ? data?.giftCount : "0"} Gifts
                          </p>
                          <div className="d-flex justify-content-evenly category-btn">
                            <div className="mx-2">
                              <button
                                className="btn btn-primary float-right btn-icon"
                                onClick={() => {
                                  dispatch({
                                    type: OPEN_DIALOGUE,
                                    payload: { data: data, type: "Category" },
                                  });
                                }}
                              >
                                <i
                                  className="fas fa-edit px-2"
                                  style={{ fontSize: "15px" }}
                                ></i>
                              </button>
                            </div>
                            <div className="mx-2">
                              <button
                                type="button"
                                className="btn btn-danger btn-icon"
                                onClick={
                                  data?.isActive === false
                                    ? false
                                    : () => handleDelete(data?._id)
                                }
                              >
                                <i
                                  class="fa fa-trash px-2"
                                  style={{ fontSize: "18px " }}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="6" align="center">
                    Nothing to show!!
                  </td>
                </tr>
              )}
            </div>
          </div>

          <div className="userFooter primeFooter"></div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getCategory, deleteCategory, categoryActive })(
  Category
);
